import cx from 'classnames'

import { HasFontCase, SanityButtonStyle } from '@data/sanity/queries/types/link'
import { SanityVideo } from '@data/sanity/queries/types/video'
import { SanityImageFragment } from '@data/sanity/queries/types/image'

import VideoButton from '@components/buttons/video-button'
import {
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'

interface VideoButtonCardProps {
  node: {
    id?: string
    text?: string
    style: SanityButtonStyle
    video: SanityVideo
    thumbnail?: SanityImageFragment
  } & HasFontCase
}

const VideoButtonCard = ({ node }: VideoButtonCardProps) => {
  const {
    id,
    text,
    video,
    thumbnail,
    fontCase,
    style: { variant, size, color, icon, iconAlignment, isFullWidth },
  } = node

  return (
    <VideoButton
      id={id}
      video={video}
      thumbnail={thumbnail}
      variant={getButtonVariant(variant)}
      size={getButtonSize(size)}
      color={getButtonColor(color)}
      icon={icon}
      iconAlignment={getButtonIconAlignment(iconAlignment)}
      className={cx('btn', { 'w-full': isFullWidth }, fontCase ?? '')}
    >
      {text}
    </VideoButton>
  )
}

VideoButtonCard.displayName = 'VideoCard'

export default VideoButtonCard
