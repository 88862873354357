import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'

import { customerCreate_customerCreate_customerUserErrors } from '@data/shopify/storefront/mutations/types/customerCreate'
import { customerAccessTokenCreate_customerAccessTokenCreate_customerUserErrors } from '@data/shopify/storefront/mutations/types/customerAccessTokenCreate'
import { customerRecover_customerRecover_customerUserErrors } from '@data/shopify/storefront/mutations/types/customerRecover'
import { customerAddressCreate_customerAddressCreate_customerUserErrors } from '@data/shopify/storefront/mutations/types/customerAddressCreate'
import { customerAddressUpdate_customerAddressUpdate_customerUserErrors } from '@data/shopify/storefront/mutations/types/customerAddressUpdate'
import { customerAddressDelete_customerAddressDelete_customerUserErrors } from '@data/shopify/storefront/mutations/types/customerAddressDelete'
import { customerDefaultAddressUpdate_customerDefaultAddressUpdate_customerUserErrors } from '@data/shopify/storefront/mutations/types/customerDefaultAddressUpdate'
import { cartCreate_cartCreate_userErrors } from '@data/shopify/storefront/mutations/types/cartCreate'
import { ErrorMessages } from '@lib/helpers'
import { getLocaleVariable, Locale } from '@lib/language'
import {
  getShopifyAdminApiUrl,
  getShopifyDomain,
  getShopifyStorefrontApiUrl,
} from '../client'

export type ShopifyClient = ApolloClient<NormalizedCacheObject>

/**
 * Returns apollo client for Shopify Storefront GraphQL API.
 */
export const getShopifyStorefrontClient = (locale: Locale) => {
  const shopifyDomain = getShopifyDomain(locale)
  const shopifyStorefrontApiUrl = getShopifyStorefrontApiUrl(shopifyDomain)
  const shopifyStorefrontAccessToken =
    getLocaleVariable(locale, 'SHOPIFY_API_TOKEN') ?? ''

  return new ApolloClient({
    uri: `${shopifyStorefrontApiUrl}/graphql.json`,
    cache: new InMemoryCache(),
    headers: shopifyStorefrontAccessToken
      ? {
          'X-Shopify-Storefront-Access-Token': shopifyStorefrontAccessToken,
        }
      : {},
  })
}

/**
 * Returns apollo client for Shopify Admin GraphQL API.
 */
export const getShopifyAdminClient = (locale: Locale) => {
  const shopifyDomain = getShopifyDomain(locale)
  const shopifyAdminApiUrl = getShopifyAdminApiUrl(shopifyDomain)
  const shopifyAdminAccessToken =
    getLocaleVariable(locale, 'SHOPIFY_API_PASSWORD') ?? ''

  return new ApolloClient({
    uri: `${shopifyAdminApiUrl}/graphql.json`,
    cache: new InMemoryCache(),
    headers: shopifyAdminAccessToken
      ? {
          'X-Shopify-Access-Token': shopifyAdminAccessToken,
        }
      : {},
  })
}

/**
 * Gets field name from Shopify GraphQL API result error.
 */
export const getGrqphQLErrorFieldName = (errorField: string[] | null) => {
  const count = errorField?.length ?? 0

  if (errorField && count > 0) {
    return errorField[count - 1]
  }
}

export enum ParseStatus {
  OK = 'ok',
  INVALID_CREDENTIALS = 'invalid_credentials',
  MISSING_CREDENTIALS = 'missing_credentials',
  UNKNOWN_ERROR = 'unknown_error',
}

export interface ParseResults {
  fieldErrors: ErrorMessages
  errorCount: number
  status: ParseStatus
}

type CustomerUserError =
  | customerCreate_customerCreate_customerUserErrors
  | customerAccessTokenCreate_customerAccessTokenCreate_customerUserErrors
  | customerRecover_customerRecover_customerUserErrors
  | customerAddressCreate_customerAddressCreate_customerUserErrors
  | customerAddressUpdate_customerAddressUpdate_customerUserErrors
  | customerAddressDelete_customerAddressDelete_customerUserErrors
  | customerDefaultAddressUpdate_customerDefaultAddressUpdate_customerUserErrors
  | cartCreate_cartCreate_userErrors

/**
 * Gets error field validation results.
 */
export const parseMutationResult = (
  customerUserErrors: CustomerUserError[]
): ParseResults => {
  const results: ParseResults = {
    fieldErrors: {},
    errorCount: customerUserErrors.length,
    status: ParseStatus.OK,
  }

  customerUserErrors.forEach((customerUserError) => {
    const fieldName = getGrqphQLErrorFieldName(customerUserError.field)

    if (fieldName) {
      results.fieldErrors[fieldName] = customerUserError.message
    }
  })

  return results
}
