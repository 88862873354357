import { LineItem } from '@lib/cart'

import CartItem from '@blocks/shop/cart-item'

interface CartItemsProps {
  items: LineItem[]
  inModal: boolean
}

const CartItems = ({ items, inModal }: CartItemsProps) => (
  <div>
    {items.map((item) => (
      <CartItem key={item.id} item={item} inModal={inModal} className="mb-10" />
    ))}
  </div>
)

export default CartItems
