import cx from 'classnames'

import Icon from '@components/icon'

interface ValidationIconProps {
  isInProgress: boolean
  isValid: boolean
}

const ValidationIcon = ({ isInProgress, isValid }: ValidationIconProps) => {
  const iconName = isInProgress ? 'Spinner' : isValid ? 'Checkmark' : 'X'

  return (
    <div
      className={cx('inline-block mt-2', {
        'animate-spin': isInProgress,
      })}
    >
      <Icon
        name={iconName}
        id="vat-validation"
        className="block h-[1em] text-current"
      />
    </div>
  )
}

export default ValidationIcon
