import { ButtonHTMLAttributes, ReactNode, useContext } from 'react'
import cx from 'classnames'

import { SanityVideo } from '@data/sanity/queries/types/video'
import { SanityImageFragment } from '@data/sanity/queries/types/image'
import { SiteContext } from '@lib/site'

import Button, { ButtonProps } from './button'
import Photo from '@components/photo'

export type VideoButtonProps = {
  video: SanityVideo
  thumbnail?: SanityImageFragment
  children?: ReactNode
} & ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>

const VideoButton = ({
  id,
  video,
  thumbnail,
  children,
  className,
  variant,
  size,
  color,
  icon,
  iconAlignment,
}: VideoButtonProps) => {
  const { toggleVideoModal } = useContext(SiteContext)

  const openModal = () => toggleVideoModal(true, video)

  if (thumbnail?.asset) {
    return (
      <button
        id={id}
        onClick={openModal}
        className="relative !opacity-100 group"
      >
        <Photo
          image={thumbnail}
          className="group-hover:brightness-90 transition duration-300"
        />

        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex items-center justify-center bg-white text-black w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] uppercase text-sm lg:text-base font-medium rounded-full p-4 group-hover:scale-105 transition-all duration-300">
            {children}
          </div>
        </div>
      </button>
    )
  }

  return (
    <Button
      id={id}
      onClick={openModal}
      variant={variant}
      size={size}
      color={color}
      icon={icon}
      iconAlignment={iconAlignment}
      className={className}
    >
      {children}
    </Button>
  )
}

export default VideoButton
