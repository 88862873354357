import groq from 'groq'

import { Preview } from '@lib/sanity/client'
import { getBlocksFragment } from './blocks'
import { blogPostOptionsFragment, blogPostWithoutBodyFragment } from './blog'
import { contentFragment } from './content'
import { imageFragment } from './image'
import { getProductFragment } from './product'
import {
  muxVideoFragment,
  videoFragment,
  vimeoVideoObjectFragment,
} from './video'

const getGridModuleFragment = (preview: Preview) => groq`
  size,
  background,
  noColumnGaps,
  noRowGaps,
  reverseSequence,
  spacing,
  columns[] {
    _key,
    sizes[] {
      breakpoint,
      width,
      justify,
      align,
      start,
      justifyContent,
      alignContent,
    },
    blocks[] {
      ${getBlocksFragment(preview)}
    },
    spacing,
    background,
    borderRadius,
  }
`

const heroModuleFragment = groq`
  bgType,
  content[] {
    ${contentFragment}
  },
  contentPosition,
  photos {
    mobilePhoto {
      ${imageFragment}
    },
    desktopPhoto {
      ${imageFragment}
    }
  },
  ${muxVideoFragment},
  videoAspectRatio {
    custom,
    base,
    sm,
    md,
    lg,
    xl
  },
  "vimeoVideo": *[_type == "vimeo.videoAsset" && ^.video.id == _id][0] {
    ${vimeoVideoObjectFragment}
  },
`

const heroCaseVideoCarouselModuleFragment = groq`
  items[] {
    content[] {
      ${contentFragment}
    },
    ${muxVideoFragment},
    "casePage": case->{
      "id": _id,
      "type": _type,
      "slug": slug.current,
      "heroModule": modules[0] {
        _key,
        _type,
        _type == 'hero' => {
          ${heroModuleFragment}
        },
      },
    },
  }
`

const heroVideoSliderModuleFragment = groq`
  slides[] {
    content[] {
      ${contentFragment}
    },
    ${muxVideoFragment},
  }
`

const dividerPhotoModuleFragment = groq`
  background,
  photo {
    ${imageFragment}
  }
`

const reviewsStringsFragment = groq`
  reviewsSingular,
  reviewsPlural,
  reviewsRatingBasedOnSingular,
  reviewsRatingBasedOnPlural,
  reviewsNoReviews,
  reviewsCarouselTitle,
  reviewsVerifiedBuyer,
  reviewsWriteReview,
  reviewsFormAuthorInputLabel,
  reviewsFormAuthorInputPlaceholder,
  reviewsFormAuthorMissing,
  reviewsFormEmailInputLabel,
  reviewsFormEmailInputPlaceholder,
  reviewsFormEmailMissing,
  reviewsFormReviewRatingInputLabel,
  reviewsFormReviewRatingMissing,
  reviewsFormReviewTitleInputLabel,
  reviewsFormReviewTitleInputPlaceholder,
  reviewsFormReviewTitleMissing,
  reviewsFormReviewMessageInputLabel,
  reviewsFormReviewMessageInputPlaceholder,
  reviewsFormReviewMessageMissing,
  reviewsFormSubmit,
  reviewsFormErrorMessage[] {
    ${contentFragment}
  },
  reviewsFormSuccessMessage[] {
    ${contentFragment}
  }
`

const reviewWidgetModuleFragment = groq`
  type,
  "reviewsStrings": *[_type == "strings" && locale == $locale][0] {
    ${reviewsStringsFragment}
  },
`

const cartFormModuleFragment = groq`
  active
`

const simpleMarqueeItemFragment = groq`
  text
`

const photoMarqueeItemFragment = groq`
  "photo": {
    ${imageFragment}
  }
`

const getProductMarqueeItemFragment = (preview: Preview) => groq`
  _id,
  "product": *[
    _type == "product" &&
    _id == ^._ref
  ][0] {
    ${getProductFragment(preview)}
  }
`

const getMarqueeModuleFragment = (preview: Preview) => groq`
  content[] {
    ${contentFragment}
  },
  contentAlignment,
  speed,
  reverse,
  pausable,
  items[] {
    _key,
    _type,
    _type == 'simple' => {
      ${simpleMarqueeItemFragment}
    },
    _type == 'photo' => {
      ${photoMarqueeItemFragment}
    },
    _type == 'product' => {
      ${getProductMarqueeItemFragment(preview)}
    }
  }
`

const collectionStringsFragment = groq`
  collectionProducts,
  collectionProductCount,
  collectionSortLabel,
  collectionSortDescription,
  collectionShowFiltersLabel,
  collectionFilters,
  collectionRemoveFilter,
  collectionClearAllLabel,
  collectionClearFiltersLabel,
  collectionFilterResultsLabel,
  collectionFilterNoResultsLabel,
  collectionFilterModalClose,
  collectionFilterNoResults[] {
    ${contentFragment}
  },
`

const collectionGridFragment = groq`
  active,
  "title": ^.title,
  "paginationLimit": *[_type == "cartSettings" && locale == $locale][0].paginationLimit,
  "filter": *[_type == "cartSettings" && locale == $locale][0].filter {
    isActive,
    groups[] {
      _key,
      title,
      "slug": slug.current,
      display,
      options[]->{
        type,
        title,
        "slug": slug.current,
        "color": color->color,
      },
    },
  },
  "sort": *[_type == "cartSettings" && locale == $locale][0].sort {
    isActive,
    options[] {
      type,
      title,
    },
  },
  "collectionStrings": *[_type == "strings" && locale == $locale][0] {
    ${collectionStringsFragment}
  },
`

const getBlogPostGridModulePostsFragment = (limit?: number) => groq`
  "posts": *[
    _type == "blogPost" &&
    type != "template" &&
    locale == $locale &&
    (!defined(^.author) || author->slug.current == ^.author->slug.current) &&
    (!defined(^.category) || categories[]->slug.current match ^.category->slug.current)
  ] | order(date desc) | order(_createdAt desc) ${
    limit ? `[0..${limit - 1}]` : ''
  } {
    ${blogPostWithoutBodyFragment}
  },
`

const blogPostGridModuleFragment = groq`
  postsPerRow,
  limitPosts && postsPerRow == 3 => {
    ${getBlogPostGridModulePostsFragment(3)}
  },
  limitPosts && postsPerRow == 4 => {
    ${getBlogPostGridModulePostsFragment(4)}
  },
  !limitPosts || !defined(postsPerRow) => {
    ${getBlogPostGridModulePostsFragment()}
  },
  options {
    ${blogPostOptionsFragment}
  },
`

const blogPostHeaderModuleFragment = groq`
  ^._type == 'blogPost' => {
    "post": ^{
      ${blogPostWithoutBodyFragment}
    },
  },
  options {
    ${blogPostOptionsFragment}
  }
`

const blogPostBodyModuleFragment = groq`
  content[] {
    ${contentFragment}
  }
`

const blogCategoryGridModuleFragment = groq`
  options {
    ${blogPostOptionsFragment}
  }
`

const blogAuthorGridModuleFragment = groq`
  options {
    ${blogPostOptionsFragment}
  }
`

const pricingPlansModuleFragment = groq`
  plans[] {
    name,
    price,
    description,
    featured,
    features,
    button {
      label,
      url,
      "page": page->{
        "id": _id,
        "type": _type,
        "slug": slug.current
      }
    }
  }
`

export const getModulesFragment = (preview: Preview) => groq`
  _key,
  _type,
  _type == 'grid' => {
    ${getGridModuleFragment(preview)}
  },
  _type == 'hero' => {
    ${heroModuleFragment}
  },
  _type == 'heroCaseVideoCarousel' => {
    ${heroCaseVideoCarouselModuleFragment}
  },
  _type == 'heroVideoSlider' => {
    ${heroVideoSliderModuleFragment}
  },
  _type == 'dividerPhoto' => {
    ${dividerPhotoModuleFragment}
  },
  _type == 'reviewWidget' => {
    ${reviewWidgetModuleFragment}
  },
  _type == 'cartForm' => {
    ${cartFormModuleFragment}
  },
  _type == 'video' => {
    ${videoFragment}
  },
  _type == 'marquee' => {
    ${getMarqueeModuleFragment(preview)}
  },
  _type == 'collectionGrid' => {
    ${collectionGridFragment}
  },
  _type == 'blogPostGrid' => {
    ${blogPostGridModuleFragment}
  },
  _type == 'blogPostHeader' => {
    ${blogPostHeaderModuleFragment}
  },
  _type == 'blogPostBody' => {
    ${blogPostBodyModuleFragment}
  },
  _type == 'blogPostReadMore' => {},
  _type == 'blogCategoryPostGrid' => {
    ${blogCategoryGridModuleFragment}
  },
  _type == 'blogAuthorPostGrid' => {
    ${blogAuthorGridModuleFragment}
  },
  _type == 'pricingPlans' => {
    ${pricingPlansModuleFragment}
  },
`
