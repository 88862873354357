import groq from 'groq'

export const vimeoVideoObjectFragment = groq`
  name,
  link,
  width,
  height,
  files[] {
    width,
    height
  }
`

export const vimeoVideoFragment = groq`
  "vimeoVideo": *[_type == "vimeo.videoAsset" && ^.source.id == _id][0] {
    ${vimeoVideoObjectFragment}
  }
`

export const muxVideoFragment = groq`
  muxVideo {
    asset->
  }
`

export const videoSettingsFragment = groq`
  settings {
    controls,
    autoplay,
    loop,
    muted
  }
`

export const videoAspectRatioFragment = groq`
  aspectRatio {
    custom,
    base,
    sm,
    md,
    lg,
    xl
  }
`

export const videoFragment = groq`
  type,
  ${muxVideoFragment},
  ${vimeoVideoFragment},
  ${videoSettingsFragment},
  ${videoAspectRatioFragment},
  borderRadius
`
