import { useContext } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { StringsContext } from '@lib/strings'

import Accordion from '@components/accordion'
import { InputChangeEvent } from '@components/input-field'
import TextArea from '@components/textarea'

interface CommentFieldProps {
  onChange?: (value: string) => void
  inModal: boolean
}

interface CommentFieldValues {
  comment: string
}

const CommentField = ({ onChange, inModal }: CommentFieldProps) => {
  const strings = useContext(StringsContext)
  const { control } = useForm<CommentFieldValues>()

  const id = `${inModal ? 'modal-' : ''}cart-comment-input`

  const handleChange = ({ target }: InputChangeEvent) => {
    if (onChange) {
      onChange(target.value)
    }
  }

  return (
    <Accordion id="cart-comment" title={strings.cartCommentSectionTitle}>
      <Controller
        name="comment"
        control={control}
        render={({
          field: { value, onChange, onBlur, ...commentRegister },
        }) => (
          <TextArea
            id={id}
            value={value}
            onChange={handleChange}
            placeholder={strings.cartCommentInputPlaceholder}
            formRegister={{
              ...commentRegister,
              onBlur: async () => onBlur(),
              onChange: async (event: InputChangeEvent) => {
                onChange(event)
                handleChange(event)
              },
            }}
          />
        )}
      />
    </Accordion>
  )
}
export default CommentField
