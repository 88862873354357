import { SanityContentQuote } from '@data/sanity/queries/types/content'

import Quote from '@components/quote'

interface QuoteCardProps {
  node: Pick<
    SanityContentQuote,
    'author' | 'authorImage' | 'alignment' | 'size'
  > & {
    text: string
  }
}

const QuoteCard = ({ node }: QuoteCardProps) => {
  const { text, author, authorImage, alignment, size } = node

  return (
    <Quote
      author={author}
      authorImage={authorImage}
      alignment={alignment}
      size={size}
    >
      {text}
    </Quote>
  )
}

QuoteCard.displayName = 'QuoteCard'

export default QuoteCard
