import { Serialiers } from '@sanity/block-content-to-react'

import AddToCartButton from './components/add-to-cart-button'
import Block from './components/block'
import Figure from './components/figure'
import HorizontalRule from './components/horizontal-rule'
import Link from './components/link'
import ColoredText from './components/colored-text'
import Quote from './components/quote'
import VideoButton from './components/video-button'
import VideoEmbed from './components/video-embed'
import InfoBox from './components/info-box'

export const serializers: Serialiers = {
  types: {
    addToCartButton: AddToCartButton,
    block: Block,
    figure: Figure,
    horizontalRule: HorizontalRule,
    quote: Quote,
    videoButton: VideoButton,
    videoEmbed: VideoEmbed,
    infoBox: InfoBox,
  },
  marks: {
    link: Link,
    textColor: ColoredText,
  },
}
