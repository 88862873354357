import cx from 'classnames'

/**
 * Gets nonce value.
 */
export const getNonce = () =>
  document.querySelector('[property="csp-nonce"]')?.getAttribute('content') ??
  ''

/**
 * Determines if stylesheet URL is added to document.
 */
const stylesheetExists = (url: string): boolean => {
  return [...document.styleSheets].some(
    ({ href }) => href && href.indexOf(url) > -1
  )
}

/**
 * Adds stylesheets to document.
 */
export const appendStylesheet = (url: string, nonce: string) => {
  // If stylesheet already exists, stop
  if (stylesheetExists(url)) {
    return
  }

  const link = document.createElement('link')
  link.href = url
  link.rel = 'stylesheet'
  link.type = 'text/css'
  link.setAttribute('nonce', nonce)

  document.head.appendChild(link)
}

/**
 * Gets CSS class that sets grid size.
 */
export const getGridSizeClass = (
  breakpoint?: string,
  size?: number,
  justify: string | boolean = false,
  align: string | boolean = false,
  start: number | boolean = false,
  justifyContent: string | boolean = false,
  alignContent: string | boolean = false
) => {
  const hasBreakpoint = breakpoint && breakpoint.trim()
  const colSpan = hasBreakpoint
    ? `${breakpoint}:col-span-${size}`
    : `col-span-${size}`
  const colStart = hasBreakpoint
    ? `${breakpoint}:col-start-${start}`
    : `col-start-${start}`
  const colJustify = hasBreakpoint ? `${breakpoint}:${justify}` : justify
  const colAlign = hasBreakpoint ? `${breakpoint}:${align}` : align
  const colJustifyContent = hasBreakpoint
    ? `${breakpoint}:flex ${breakpoint}:flex-col ${breakpoint}:${justifyContent}`
    : `flex flex-col ${justifyContent}`
  const colAlignContent = hasBreakpoint
    ? `${breakpoint}:flex ${breakpoint}:flex-col ${breakpoint}:${alignContent}`
    : `flex flex-col ${alignContent}`

  return cx(
    colSpan,
    start && colStart,
    justify && colJustify,
    align && colAlign,
    justifyContent && colJustifyContent,
    alignContent && colAlignContent
  )
}

/**
 * Gets CSS class that sets grid item order.
 */
export const getItemOrderClass = (
  index: number,
  breakpoint?: string,
  size?: number,
  totalSize?: number,
  reverseWhenFullWidth?: boolean
) => {
  const hasBreakpoint = breakpoint && breakpoint.trim()
  const orderIndex =
    reverseWhenFullWidth && size === totalSize ? 12 - index : index + 1
  const order = hasBreakpoint
    ? `${breakpoint}:order-${Math.abs(orderIndex)}`
    : `order-${Math.abs(orderIndex)}`

  return cx(`${orderIndex < 0 ? '-' : ''}${order}`)
}
