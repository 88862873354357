import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react'

import {
  SanityHeroModule,
  SanityModule,
} from '@data/sanity/queries/types/modules'
import { isEqual } from './helpers'

interface PageHeroContextProps {
  sourcePage?: string
  setSourcePage: Dispatch<SetStateAction<string | undefined>>
  targetPage?: string
  setTargetPage: Dispatch<SetStateAction<string | undefined>>
  heroModule?: SanityModule
  caseHeroModule?: SanityHeroModule
  setCaseHeroModule: Dispatch<SetStateAction<SanityHeroModule | undefined>>
  heroCaseVideoCarouselDisabled: boolean
  setHeroCaseVideoCarouselDisabled: Dispatch<SetStateAction<boolean>>
}

interface PageHeroContextProviderProps {
  modules?: SanityModule[] | null
  children: ReactNode
}

const filterHeroModules = (modules: SanityModule[]) =>
  modules
    // Filter hero modules
    .filter(
      (module, index) =>
        index === 0 && ['hero', 'heroCaseVideoCarousel'].includes(module._type)
    )
    .filter(Boolean)

export const PageHeroContext = createContext<PageHeroContextProps>({
  setSourcePage: () => null,
  setTargetPage: () => null,
  setCaseHeroModule: () => null,
  heroCaseVideoCarouselDisabled: false,
  setHeroCaseVideoCarouselDisabled: () => null,
})

export const PageHeroContextProvider = ({
  modules,
  children,
}: PageHeroContextProviderProps) => {
  const [sourcePage, setSourcePage] = useState<string>()
  const [targetPage, setTargetPage] = useState<string>()
  const [heroModule, setHeroModule] = useState<SanityModule | undefined>(
    () => filterHeroModules(modules ?? [])?.[0]
  )
  const [caseHeroModule, setCaseHeroModule] = useState<SanityHeroModule>()
  const [heroCaseVideoCarouselDisabled, setHeroCaseVideoCarouselDisabled] =
    useState(false)

  // Update hero module when a new page is loaded
  useEffect(() => {
    const filteredModule = filterHeroModules(modules ?? [])?.[0]

    if (!isEqual(filteredModule, heroModule)) {
      setHeroModule(filteredModule)
    }
  }, [modules, heroModule])

  return (
    <PageHeroContext.Provider
      value={{
        sourcePage,
        setSourcePage,
        targetPage,
        setTargetPage,
        heroModule,
        caseHeroModule,
        setCaseHeroModule,
        heroCaseVideoCarouselDisabled,
        setHeroCaseVideoCarouselDisabled,
      }}
    >
      {children}
    </PageHeroContext.Provider>
  )
}
