import createSanityClient, { ClientConfig, SanityClient } from '@sanity/client'
import sanityImage from '@sanity/image-url'

import { CustomPreviewData } from '@lib/routes'

const options: ClientConfig = {
  projectId: process.env.SANITY_PROJECT_ID,
  dataset: process.env.SANITY_PROJECT_DATASET,
  useCdn: process.env.NODE_ENV === 'production',
  apiVersion: '2021-03-25',
}

export const sanityClient = createSanityClient(options)
export const imageBuilder = sanityImage(sanityClient)

export interface Preview {
  active: boolean
  token?: string
}

export const getPreview = (
  preview?: boolean,
  previewData?: CustomPreviewData
): Preview => ({
  active: !!preview,
  token: previewData?.token,
})

/**
 * Creates a Sanity client for preview.
 */
const createPreviewClient = (token: string): SanityClient => {
  return createSanityClient({
    ...options,
    useCdn: false,
    token,
  })
}

/**
 * Creates a Sanity client.
 */
export const getSanityClient = (preview?: Preview): SanityClient => {
  if (preview?.active && preview?.token) {
    return createPreviewClient(preview.token)
  }

  return sanityClient
}

/**
 * Minifies a GROQ query by removing spaces except around names, e.g., "x in y".
 */
export const minifyQuery = (query: string) =>
  query.replace(/^\s|\s$|\B\s|\s\B/g, '')

/**
 * Fetches data using a GROQ query.
 */
export const fetchQuery = async <T, P = Record<string, never>>(
  query: string,
  params?: P,
  preview?: Preview
) => getSanityClient(preview).fetch<T>(minifyQuery(query), params ?? {})
