import groq from 'groq'

import { blogSettingsFragment } from './blog'
import { contentFragment } from './content'
import { imageFragment } from './image'
import { linkFragment, linkPageFragment } from './link'
import { getProductFragment } from './product'
import { Preview } from '@lib/sanity/client'

const getHeaderDesktopMenuFragment = (preview: Preview) => groq`
  "slug": slug.current,
  items[] {
    ${linkFragment},
    dropdownItems[] {
      ${linkFragment}
    },
    featured[]->{
      ${getProductFragment(preview)}
    },
  }
`

const headerMobileMenuFragment = groq`
  "slug": slug.current,
  items[] {
    ${linkFragment},
    dropdownItems[] {
      ${linkFragment}
    }
  }
`

const headerPromoFragment = groq`
  enabled,
  display,
  text,
  "link": link->{
    ${linkPageFragment}
  },
`

const getHeaderFragment = (preview: Preview) => groq`
  menuDesktopLeft->{
    ${getHeaderDesktopMenuFragment(preview)}
  },
  menuDesktopRight->{
    ${getHeaderDesktopMenuFragment(preview)}
  },
  menuMobilePrimary->{
    ${headerMobileMenuFragment}
  },
  menuMobileSecondary->{
    ${headerMobileMenuFragment}
  },
  "promo": *[_type == "promoSettings" && locale == $locale][0] {
    ${headerPromoFragment}
  },
`

export const newsletterFragment = groq`
  service,
  hubSpotFormId,
  klaviyoListID,
  variant,
  title,
  description,
  submit,
  successMsg[] {
    ${contentFragment}
  },
  errorMsg[] {
    ${contentFragment}
  },
  terms[] {
    ${contentFragment}
  }
`

const footerBlock1Fragment = groq`
  "title": blockTitle1,
  "description": blockDescription1[] {
    ${contentFragment}
  },
`

const footerBlock2Fragment = groq`
  "title": blockTitle2,
  "description": blockDescription2[] {
    ${contentFragment}
  },
`

const footerBlock3Fragment = groq`
  "title": blockTitle3,
  "description": blockDescription3[] {
    ${contentFragment}
  },
  social[] {
    _key,
    name,
    url
  },
`

const footerBlock4Fragment = groq`
  "title": blockTitle4,
  "description": blockDescription4[] {
    ${contentFragment}
  },
  newsletter {
    ${newsletterFragment}
  },
`

const footerFragment = groq`
  "blocks": [
    {
      ${footerBlock1Fragment}
    },
    {
      ${footerBlock2Fragment}
    },
    {
      ${footerBlock3Fragment}
    },
    {
      ${footerBlock4Fragment}
    }
  ],
  copyright,
  paymentMethods[] {
    ${imageFragment}
  },
`

const productCountsFragment = groq`
  "slug": slug.current,
  "count": count(products),
`

const cartFragment = groq`
  storeURL,
  taxRate,
  message,
  openInSeparatePage,
  showVatId,
  showComment,
  terms[] {
    ${contentFragment}
  },
`

const cookieConsentFragment = groq`
  enabled,
  message,
  "link": link->{
    ${linkPageFragment}
  },
`

const seoFragment = groq`
  siteTitle,
  metaTitle,
  metaDesc,
  shareTitle,
  shareDesc,
  shareGraphic,
`

const siteStringsFragment = groq`
  loadingPageTitle,
  languageSwitch,
  cartLink,
  featuredProducts,
  skipToContent,
  goHomeLabel,
  mobileMenuLabel,
  productLowStock,
  productOutOfStock,
  productPriceLabel,
  productColorOptionLabel,
  productDiscountText,
  productIncreaseQuantity,
  productDecreaseQuantity,
  productEnterQuantity,
  cartTitle,
  cartClose,
  cartEmpty,
  cartVatSectionTitle,
  cartVatIdInputPlaceholder,
  cartVatIdError,
  cartInvalidVatIdError,
  cartCommentSectionTitle,
  cartCommentInputPlaceholder,
  cartDiscount,
  cartSubtotal,
  cartTotal,
  cartSubmit,
  cartAddToCartErrorMessage[] {
    ${contentFragment}
  },
  carouselCounterText,
  carouselLeftArrowLabel,
  carouselRightArrowLabel,
  carouselDotLabel,
  buttonAddToCart,
  buttonRemove,
  buttonAccept,
  buttonLearnMore,
  buttonTryAgain,
  buttonLoadMore,
  buttonUnavailable,
  buttonWaiting,
  buttonLoading,
  buttonAdding,
  buttonUpdating,
  buttonSubmitting,
  buttonEdit,
  buttonDelete,
  buttonCancel,
  emailAddress,
  emailAddressPlaceholder,
  emailMissing,
  emailInvalid,
  firstName,
  firstNamePlaceholder,
  firstNameMissing,
  lastName,
  lastNamePlaceholder,
  lastNameMissing,
  fullName,
  fullNamePlaceholder,
  fullNameMissing,
  phoneNumber,
  phoneNumberPlaceholder,
  phoneNumberMissing,
  phoneNumberInvalid,
  company,
  companyPlaceholder,
  productWaitlistSubmit,
  productWaitlistSuccess,
  productWaitlistError,
`

const identityFragment = groq`
  logo {
    ${imageFragment}
  },
  invertedLogo {
    ${imageFragment}
  },
`

const publicSettingsFragment = groq`
  siteURL,
  hubSpotPortalId,
  klaviyoAccountID,
  gtmContainerId,
  analyticsId,
  facebookEvents,
  facebookPixelId,
  facebookDomainVerification,
  facebookTestEventCode,
  stampedApiKey,
  partnerAdsTracking,
  cookieBotId,
  pageAnimation,
`

const allSettingsFragment = groq`
  hubSpotAccessToken,
  klaviyoPrivateKey,
  stampedStoreHash,
  ${publicSettingsFragment}
`

const discountFragment = groq`
  _id,
  type,
  title,
  discountValuePercent,
  ...select(type == 'quantity' => {
    minimumQuantity,
    maximumQuantity,
  }),
  ...select(type == 'bundle' => {
    products[] {
      "id": selection.id,
      "variantIds": selection.variants[selected == true].id
    },
    doNotStackWithQuantityDiscounts,
  }),
`

export const publicSiteSettingsQuery = groq`
*[_type == "generalSettings" && locale == $locale][0] {
  ${publicSettingsFragment}
}
`

export const allSiteSettingsQuery = groq`
  *[_type == "generalSettings" && locale == $locale][0] {
    ${allSettingsFragment}
  }
`

export const discountQuery = groq`
  *[_type == "discount" && locale == $locale && enabled == true] {
    ${discountFragment}
  }
`

export const getSiteFragment = (preview: Preview) => groq`
  "publicLocales": *[_type == "generalSettings" && public == true][].locale,
  "settings": *[_type == "generalSettings" && locale == $locale][0] {
    ${publicSettingsFragment}
  },
  "identity": *[_type == "identitySettings" && locale == $locale][0] {
    ${identityFragment}
  },
  "cart": *[_type == "cartSettings" && locale == $locale][0] {
    ${cartFragment}
  },
  "blogSettings": *[_type == "blogSettings" && locale == $locale][0] {
    ${blogSettingsFragment}
  },
  "productCounts": [{
    "slug": "all",
    "count": count(*[_type == "product" && locale == $locale])
  }] + *[_type == "collection" && locale == $locale] {
    ${productCountsFragment}
  },
  "cookieConsent": *[_type == "cookieSettings" && locale == $locale][0] {
    ${cookieConsentFragment}
  },
  "header": *[_type == "headerSettings" && locale == $locale][0] {
    ${getHeaderFragment(preview)}
  },
  "footer": *[_type == "footerSettings" && locale == $locale][0] {
    ${footerFragment}
  },
  "seo": *[_type == "seoSettings" && locale == $locale][0] {
    ${seoFragment}
  },
  "siteStrings": *[_type == "strings" && locale == $locale][0] {
    ${siteStringsFragment}
  },
  "discounts": *[_type == "discount" && locale == $locale && enabled == true] {
    ${discountFragment}
  },
`

export const getSiteQuery = (preview: Preview) => groq`
  ${getSiteFragment(preview)}
`
