import { AnchorHTMLAttributes } from 'react'
import cx from 'classnames'

import {
  ButtonProps,
  getButtonStyles,
  ButtonIcon,
  iconSizeClasses,
  textColorClasses,
} from './button'

const ButtonLink = ({
  id,
  children,
  className,
  href,
  target,
  rel,
  tabIndex,
  style,
  onClick,
  variant,
  size,
  color,
  icon,
  iconSize,
  iconAlignment,
  isActive,
}: ButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <a
      id={id}
      href={href}
      className={cx(
        'group',
        getButtonStyles({ variant, size, color, isActive }),
        className
      )}
      target={target}
      tabIndex={tabIndex}
      rel={rel}
      style={style}
      onClick={onClick}
    >
      {children}
      {icon && (
        <ButtonIcon
          name={icon}
          alignment={iconAlignment}
          className={cx(
            'text-current',
            iconSize ? iconSizeClasses[iconSize] : {}
          )}
        />
      )}
    </a>
  )
}

export default ButtonLink
