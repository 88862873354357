import { SanityContentInfoBox } from '@data/sanity/queries/types/content'

interface InfoBoxProps {
  node: Pick<SanityContentInfoBox, 'title' | 'content'>
}

const InfoBox = ({ node }: InfoBoxProps) => {
  const { title, content } = node

  return (
    <div className="info-box bg-green-light text-black rounded-md p-6 sm:p-8">
      <h4 className="!mb-4 text-[1.375rem] leading-[1.625rem]">{title}</h4>
      {content && (
        <p
          className="whitespace-pre-wrap !my-0 text-[1.125rem] leading-[1.625rem]"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
  )
}

InfoBox.displayName = 'InfoBox'

export default InfoBox
