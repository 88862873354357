import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from 'react'

import { SanityModule } from '@data/sanity/queries/types/modules'

interface PageModuleContextProps {
  pageModules: SanityModule[]
  casePageModules?: SanityModule[]
  setCasePageModules: Dispatch<SetStateAction<SanityModule[] | undefined>>
}

interface PageModuleContextProviderProps {
  modules?: SanityModule[] | null
  children: ReactNode
}

const filterPageModules = (modules: SanityModule[]) =>
  modules
    // Exclude hero module, if it is the first module
    .filter(
      (module, index) =>
        !(
          index === 0 &&
          ['hero', 'heroCaseVideoCarousel'].includes(module._type)
        )
    )
    .filter(Boolean)

export const PageModuleContext = createContext<PageModuleContextProps>({
  pageModules: [],
  setCasePageModules: () => null,
})

export const PageModuleContextProvider = ({
  modules,
  children,
}: PageModuleContextProviderProps) => {
  const [casePageModules, setCasePageModules] = useState<SanityModule[]>()

  const pageModules = useMemo(() => filterPageModules(modules ?? []), [modules])

  return (
    <PageModuleContext.Provider
      value={{
        pageModules,
        casePageModules,
        setCasePageModules,
      }}
    >
      {children}
    </PageModuleContext.Provider>
  )
}
