import Cookies from 'js-cookie'

import { AttributeInput } from '@data/shopify/types/globalTypes'

interface PartnerAdsSettings {
  enabled: boolean
}

interface PartnerAdsVariables {
  partnerId?: string
  clickId?: string
}

const STORAGE_TIMEOUT = 40

const partnerAdsSettings: PartnerAdsSettings = {
  enabled: false,
}

/**
 * Reads current URL parameters and stores PartnerAds IDs.
 */
const storeUrlParameters = () => {
  const url = new URL(window.location.href)

  if (!url.searchParams.has('pa-partnerid') || !url.searchParams.has('pacid')) {
    return
  }

  const date = new Date().getTime().toString()
  const partnerId = url.searchParams.get('pa-partnerid') ?? ''
  const clickId = url.searchParams.get('pacid') ?? ''

  localStorage.setItem('x-pa-startTime', date)
  localStorage.setItem('x-pa-id', partnerId)
  localStorage.setItem('x-pa-pacid', clickId)

  Cookies.set('x-pa-startTime', date, { expires: STORAGE_TIMEOUT })
  Cookies.set('x-pa-id', partnerId, { expires: STORAGE_TIMEOUT })
  Cookies.set('x-pa-pacid', clickId, { expires: STORAGE_TIMEOUT })
}

/**
 * Checks if the local storage item or cookie has expired.
 */
const isExpired = () => {
  const date = new Date().getTime()
  const startTime =
    localStorage.getItem('x-pa-startTime') ?? Cookies.get('x-pa-startTime')
  const interval = 1000 * 60 * 60 * 24 * STORAGE_TIMEOUT

  if (!startTime || date - parseInt(startTime, 10) > interval) {
    localStorage.removeItem('x-pa-startTime')
    localStorage.removeItem('x-pa-id')
    localStorage.removeItem('x-pa-pacid')

    Cookies.remove('x-pa-startTime')
    Cookies.remove('x-pa-id')
    Cookies.remove('x-pa-pacic')

    return true
  }

  return false
}

/**
 * Gets stored PartnerAds parameters.
 */
const getParameters = (): PartnerAdsVariables => {
  if (!partnerAdsSettings.enabled || isExpired()) {
    return {}
  }

  return {
    partnerId: localStorage.getItem('x-pa-id') ?? Cookies.get('x-pa-id'),
    clickId: localStorage.getItem('x-pa-pacid') ?? Cookies.get('x-pa-pacid'),
  }
}

/**
 * Enables PartnerAds and loads parameters.
 */
export const partnerAdsLoad = () => {
  partnerAdsSettings.enabled = true

  storeUrlParameters()
}

/**
 * Gets cart attributes for PartnerAds.
 */
export const getPartnerAdsCartAttributes = (
  isPrivate = false
): AttributeInput[] => {
  const parameters = getParameters()

  if (
    !partnerAdsSettings.enabled ||
    !parameters.partnerId ||
    !parameters.clickId
  ) {
    return []
  }

  const keyPrefix = isPrivate ? '_' : ''

  return [
    {
      key: `${keyPrefix}x-pa-id`,
      value: parameters.partnerId,
    },
    {
      key: `${keyPrefix}x-pa-pacid`,
      value: parameters.clickId,
    },
    {
      key: `${keyPrefix}x-pacid`,
      value: parameters.clickId,
    },
  ]
}
