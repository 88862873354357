import cx from 'classnames'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import {
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useContext,
} from 'react'

import {
  SanityContentBlockLinkPage,
  SanityLink,
} from '@data/sanity/queries/types/content'
import {
  SanityButtonStyle,
  SanityFontCase,
} from '@data/sanity/queries/types/link'
import {
  SanityColor,
  SanityHeroModule,
} from '@data/sanity/queries/types/modules'
import { getCasePage } from '@lib/sanity/page'
import { useLogoutUser } from '@lib/auth'
import { getSanityImageUrl } from '@lib/image'
import { LanguageContext } from '@lib/language'
import { LayoutContext } from '@lib/layout-context'
import { PageHeroContext } from '@lib/page-hero-context'
import { PageModuleContext } from '@lib/page-module-context'
import { getPageUrl, isRouteCollection, PageType } from '@lib/routes'
import { SeoContext } from '@lib/seo-context'
import { SiteContext } from '@lib/site'

import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import ButtonLink from '@components/buttons/button-link'

interface LinkFrameProps {
  buttonStyles: SanityButtonStyle
  children: ReactNode
}

interface InternalLinkProps {
  pageType: PageType
  pageSlug?: string
  id?: string
  tabIndex?: number
  ariaLabel?: string
  buttonVariant?: ButtonVariant
  buttonSize?: ButtonSize
  buttonColor?: ButtonColor
  buttonStyles?: SanityButtonStyle
  isButton?: boolean
  showCollectionCount?: boolean
  onClick?: () => void
  fontCase?: SanityFontCase
  className?: string
  children?: ReactNode
}

interface ExternalLinkProps {
  url: string
  id?: string
  tabIndex?: number
  ariaLabel?: string
  buttonVariant?: ButtonVariant
  buttonSize?: ButtonSize
  buttonColor?: ButtonColor
  buttonStyles?: SanityButtonStyle
  isButton?: boolean
  fontCase?: SanityFontCase
  className?: string
  children?: ReactNode
}

interface CasePageLinkProps {
  casePage: SanityContentBlockLinkPage
  id?: string
  tabIndex?: number
  ariaLabel?: string
  buttonVariant?: ButtonVariant
  buttonSize?: ButtonSize
  buttonColor?: ButtonColor
  buttonStyles?: SanityButtonStyle
  isButton?: boolean
  onClick?: () => void
  fontCase?: SanityFontCase
  className?: string
  children?: ReactNode
}

interface LinkProps {
  link: SanityLink
  onClick?: () => void
  children?: ReactNode
  showCollectionCount?: boolean
  buttonVariant?: ButtonVariant
  buttonSize?: ButtonSize
  buttonColor?: ButtonColor
}

export const frameColorClasses: Record<SanityColor, string> = {
  [SanityColor.WHITE]: 'text-white',
  [SanityColor.GRAY]: 'text-gray',
  [SanityColor.GREEN_LIGHT]: 'text-green-light',
  [SanityColor.GREEN]: 'text-green',
  [SanityColor.GREEN_ELECTRIC]: 'text-green-electric',
  [SanityColor.GREEN_DARK]: 'text-green-dark',
  [SanityColor.PINK_FADED]: 'text-pink-faded',
  [SanityColor.PINK]: 'text-pink',
  [SanityColor.PINK_HOT]: 'text-pink-hot',
  [SanityColor.BLUE]: 'text-blue',
  [SanityColor.RED]: 'text-red',
  [SanityColor.YELLOW]: 'text-yellow',
}

const LinkFrame = ({ buttonStyles, children }: LinkFrameProps) => {
  return (
    <div
      className={cx(
        'relative inline-flex',
        buttonStyles.color
          ? frameColorClasses[buttonStyles.color as SanityColor]
          : undefined,
        {
          'p-2': buttonStyles.size === ButtonSize.SMALL,
          'p-4': buttonStyles.size === ButtonSize.NORMAL,
          'p-8 md:p-10': buttonStyles.size === ButtonSize.LARGE,
        }
      )}
    >
      <svg
        className="absolute top-[50%] translate-y-[-50%] inset-x-0 w-full"
        viewBox="0 0 680 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M670 0H610V10H670L670 70H680V10V0H670Z"
          className="fill-current"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M680 210L680 150L670 150L670 210L610 210L610 220L670 220L680 220L680 210Z"
          className="fill-current"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 220L70 220L70 210L10 210L10 150L6.11959e-06 150L8.74228e-07 210L0 220L10 220Z"
          className="fill-current"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.86805e-06 10L0 70L10 70L10 10L70 10L70 9.17939e-06L10 1.31134e-06L9.17939e-06 0L7.86805e-06 10Z"
          className="fill-current"
        />
      </svg>

      <div className="relative inline-flex z-1">{children}</div>
    </div>
  )
}

const useHandleCustomInternalNavigation = (
  pageType: PageType,
  pageSlug?: string
) => {
  const { togglePageTransition } = useContext(SiteContext)
  const { sourcePage, targetPage } = useContext(PageHeroContext)

  const router = useRouter()
  const logoutUser = useLogoutUser()

  return useCallback(
    (
      event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>
    ) => {
      // Logout user and redirect if link target is logout page
      if (pageType === PageType.LOGOUT_PAGE) {
        event.preventDefault()

        logoutUser(() => {
          router.push(getPageUrl(PageType.LOGIN_PAGE))
        })
        return
      }

      const pageUrl = getPageUrl(pageType, pageSlug)

      // Manually switch the page, if it is showing a different page than what Next.js expects
      if (!!sourcePage && !!targetPage && sourcePage === pageUrl) {
        event.preventDefault()

        window.location.assign(pageUrl)
        togglePageTransition(true)
        return
      }
    },
    [
      logoutUser,
      pageType,
      pageSlug,
      router,
      sourcePage,
      targetPage,
      togglePageTransition,
    ]
  )
}

const InternalLink = ({
  pageType,
  pageSlug,
  id,
  tabIndex,
  ariaLabel,
  buttonVariant,
  buttonSize,
  buttonColor,
  buttonStyles,
  isButton,
  showCollectionCount,
  onClick,
  fontCase,
  className,
  children,
}: InternalLinkProps) => {
  const { getProductCount } = useContext(SiteContext)

  const handleCustomInternalNavigation = useHandleCustomInternalNavigation(
    pageType,
    pageSlug
  )

  const pageUrl = getPageUrl(pageType, pageSlug)
  const isCollection = !!pageType && isRouteCollection(pageType)
  const collectionSlug = isCollection && pageSlug ? pageSlug : 'all'
  const collectionCount = getProductCount(collectionSlug)

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      handleCustomInternalNavigation(event)
      onClick && onClick()
    },
    [handleCustomInternalNavigation, onClick]
  )
  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLButtonElement>) => {
      handleCustomInternalNavigation(event)
      onClick && onClick()
    },
    [handleCustomInternalNavigation, onClick]
  )

  const internalLinkButton = (
    <NextLink href={pageUrl}>
      <Button
        id={id}
        role="link"
        tabIndex={tabIndex}
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        aria-label={ariaLabel}
        variant={buttonVariant ?? getButtonVariant(buttonStyles?.variant)}
        size={buttonSize ?? getButtonSize(buttonStyles?.size)}
        color={buttonColor ?? getButtonColor(buttonStyles?.color)}
        icon={buttonStyles?.icon}
        iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
        className={cx(
          {
            btn: isButton,
            'w-full': buttonStyles?.isFullWidth,
          },
          fontCase ?? '',
          className
        )}
      >
        {children}

        {showCollectionCount && isCollection && (
          <span
            aria-hidden="true"
            className="inline-block relative ml-2 leading-none align-super text-[.5em]"
          >
            {collectionCount}
          </span>
        )}
      </Button>
    </NextLink>
  )

  return buttonStyles?.frame ? (
    <LinkFrame buttonStyles={buttonStyles}>{internalLinkButton}</LinkFrame>
  ) : (
    internalLinkButton
  )
}

const ExternalLink = ({
  url,
  id,
  tabIndex,
  ariaLabel,
  buttonVariant,
  buttonSize,
  buttonColor,
  buttonStyles,
  isButton,
  fontCase,
  className,
  children,
}: ExternalLinkProps) => {
  const externalLinkButton = (
    <ButtonLink
      id={id}
      href={url}
      target="_blank"
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      rel="noopener noreferrer"
      variant={buttonVariant ?? getButtonVariant(buttonStyles?.variant)}
      size={buttonSize ?? getButtonSize(buttonStyles?.size)}
      color={buttonColor ?? getButtonColor(buttonStyles?.color)}
      icon={buttonStyles?.icon}
      iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
      className={cx(
        { btn: isButton, 'w-full': buttonStyles?.isFullWidth },
        fontCase ?? '',
        className
      )}
    >
      {children}
    </ButtonLink>
  )

  return buttonStyles?.frame ? (
    <LinkFrame buttonStyles={buttonStyles}>{externalLinkButton}</LinkFrame>
  ) : (
    externalLinkButton
  )
}

const useOpenCasePage = () => {
  const { togglePageTransition } = useContext(SiteContext)
  const { locale } = useContext(LanguageContext)
  const { setCasePageModules } = useContext(PageModuleContext)
  const {
    setSourcePage,
    setTargetPage,
    setHeroCaseVideoCarouselDisabled,
    setCaseHeroModule,
  } = useContext(PageHeroContext)
  const {
    setSiteTitle,
    setMetaTitle,
    setMetaDescription,
    setShareTitle,
    setShareDescription,
    setShareGraphicUrl,
  } = useContext(SeoContext)
  const { setIsHeaderTransparent, setHeaderBackgroundColor } =
    useContext(LayoutContext)

  const router = useRouter()

  return useCallback(
    async (caseSlug: string) => {
      // Start route change progress bar
      togglePageTransition(true)

      // Disable case video carousel (continue the current video)
      setHeroCaseVideoCarouselDisabled(true)

      // Get case page data
      const casePageData = await getCasePage(locale, caseSlug, {
        active: false,
      })

      // Update hero module
      const newHeroModule = (casePageData.page?.modules ?? []).filter(
        (module, index) => index === 0 && module._type === 'hero'
      )[0] as SanityHeroModule | undefined
      setCaseHeroModule(newHeroModule)

      // Replace page modules
      const newCasePageModules = (casePageData.page?.modules ?? []).filter(
        (module, index) => !(index === 0 && module._type === 'hero')
      )
      setCasePageModules(newCasePageModules)

      // Update page title and SEO metadata
      setSiteTitle(casePageData.site.seo?.siteTitle)
      setMetaTitle(
        casePageData?.page?.seo?.metaTitle ?? casePageData.site.seo?.metaTitle
      )
      setMetaDescription(
        casePageData?.page?.seo?.metaDesc ?? casePageData.site.seo?.metaDesc
      )
      setShareTitle(
        casePageData?.page?.seo?.shareTitle ?? casePageData.site.seo?.shareTitle
      )
      setShareDescription(
        casePageData?.page?.seo?.shareDesc ?? casePageData.site.seo?.shareDesc
      )
      setShareGraphicUrl(
        getSanityImageUrl(
          casePageData?.page?.seo?.shareGraphic ??
            casePageData.site.seo?.shareGraphic,
          {
            width: 1200,
            height: 630,
          }
        )
      )

      // Update header color and transparency
      setHeaderBackgroundColor(casePageData.page?.headerBackgroundColor)
      setIsHeaderTransparent(!!casePageData.page?.hasTransparentHeader)

      // Update page URL using window history not Next.js router (see also _app.tsx handlePopState)
      const casePageTitle =
        casePageData?.page?.seo?.metaTitle ?? casePageData.site.seo?.metaTitle
      const casePageUrl = getPageUrl(PageType.CASE, caseSlug)
      window.history.pushState({}, casePageTitle ?? '', casePageUrl)

      // Finish route change progress bar
      togglePageTransition(false)

      // Save URLs to fix issues with navigation not working
      setSourcePage(router.asPath)
      setTargetPage(casePageUrl)
    },
    [
      locale,
      router,
      setCaseHeroModule,
      setCasePageModules,
      setHeaderBackgroundColor,
      setHeroCaseVideoCarouselDisabled,
      setIsHeaderTransparent,
      setMetaDescription,
      setMetaTitle,
      setShareDescription,
      setShareGraphicUrl,
      setShareTitle,
      setSiteTitle,
      setSourcePage,
      setTargetPage,
      togglePageTransition,
    ]
  )
}

const CasePageLink = ({
  casePage,
  id,
  tabIndex,
  ariaLabel,
  buttonVariant,
  buttonSize,
  buttonColor,
  buttonStyles,
  isButton,
  onClick,
  fontCase,
  className,
  children,
}: CasePageLinkProps) => {
  const openCasePage = useOpenCasePage()

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()

      openCasePage(casePage.slug ?? '')
      onClick && onClick()
    },
    [casePage, onClick, openCasePage]
  )
  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLButtonElement>) => {
      event.preventDefault()

      openCasePage(casePage.slug ?? '')
      onClick && onClick()
    },
    [casePage, onClick, openCasePage]
  )

  const casePageLinkButton = (
    <Button
      id={id}
      role="link"
      tabIndex={tabIndex}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      aria-label={ariaLabel}
      variant={buttonVariant ?? getButtonVariant(buttonStyles?.variant)}
      size={buttonSize ?? getButtonSize(buttonStyles?.size)}
      color={buttonColor ?? getButtonColor(buttonStyles?.color)}
      icon={buttonStyles?.icon}
      iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
      className={cx(
        {
          btn: isButton,
          'w-full': buttonStyles?.isFullWidth,
        },
        fontCase ?? '',
        className
      )}
    >
      {children}
    </Button>
  )

  return buttonStyles?.frame ? (
    <LinkFrame buttonStyles={buttonStyles}>{casePageLinkButton}</LinkFrame>
  ) : (
    casePageLinkButton
  )
}

const Link = ({
  link,
  children,
  tabIndex,
  onClick,
  className,
  showCollectionCount,
  buttonVariant,
  buttonSize,
  buttonColor,
  'aria-label': ariaLabel,
}: LinkProps & HTMLAttributes<HTMLAnchorElement>) => {
  const { id, page, url, casePage, isButton, fontCase, buttonStyles } = link

  // (A) Internal page link
  if (page) {
    return (
      <InternalLink
        pageType={page.type as PageType}
        pageSlug={page.slug}
        id={id}
        tabIndex={tabIndex}
        ariaLabel={ariaLabel}
        buttonVariant={buttonVariant}
        buttonSize={buttonSize}
        buttonColor={buttonColor}
        buttonStyles={buttonStyles}
        isButton={isButton}
        showCollectionCount={showCollectionCount}
        onClick={onClick}
        fontCase={fontCase}
        className={className}
      >
        {children}
      </InternalLink>
    )
  }

  // (B) External link
  if (url) {
    return (
      <ExternalLink
        url={url}
        id={id}
        tabIndex={tabIndex}
        ariaLabel={ariaLabel}
        buttonVariant={buttonVariant}
        buttonSize={buttonSize}
        buttonColor={buttonColor}
        buttonStyles={buttonStyles}
        isButton={isButton}
        fontCase={fontCase}
        className={className}
      >
        {children}
      </ExternalLink>
    )
  }

  // (C) Case page link
  if (casePage) {
    return (
      <CasePageLink
        casePage={casePage}
        id={id}
        tabIndex={tabIndex}
        ariaLabel={ariaLabel}
        buttonVariant={buttonVariant}
        buttonSize={buttonSize}
        buttonColor={buttonColor}
        buttonStyles={buttonStyles}
        isButton={isButton}
        onClick={onClick}
        fontCase={fontCase}
        className={className}
      >
        {children}
      </CasePageLink>
    )
  }

  return null
}

export default Link
