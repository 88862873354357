import { HTMLAttributes, ReactNode } from 'react'
import NextLink from 'next/link'

interface SimpleLinkProps {
  href: string
  className?: string
  children?: ReactNode
}

const SimpleLink = ({
  href,
  onClick,
  onKeyPress,
  tabIndex,
  children,
  className,
}: SimpleLinkProps & HTMLAttributes<HTMLAnchorElement>) => (
  <NextLink href={href}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a
      className={className}
      onClick={onClick}
      onKeyPress={onKeyPress}
      tabIndex={tabIndex}
      role="link"
    >
      {children}
    </a>
  </NextLink>
)

export default SimpleLink
