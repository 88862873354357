import { ImageLoader, ImageProps } from 'next/image'
import {
  ImageFormat,
  SanityImageSource,
} from '@sanity/image-url/lib/types/types'

import { imageBuilder } from './sanity/client'
import { parseJson } from './helpers'
import {
  SanityBorderRadius,
  SanityImageFragment,
  SanityImageFragmentWithContent,
} from '@data/sanity/queries/types/image'

interface SourceOptionsProps {
  width?: number
  height?: number
  format?: ImageFormat
  quality?: number
  fitMax?: boolean
  autoFormat?: boolean
}

export type LayoutValue = ImageProps['layout']

export type ObjectFitValue = ImageProps['objectFit']

export type PhotoImage = SanityImageFragment | SanityImageFragmentWithContent

export const borderRadiusClassMap: Record<SanityBorderRadius, string> = {
  none: 'none',
  default: 'rounded',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  full: 'rounded-full',
}

/**
 * Gets image loader for Next.js image component.
 */
export const getSanityImageLoader: ImageLoader = ({ src, width, quality }) => {
  const parsedSource = parseJson(src)
  const image = parsedSource?.asset
    ? (parsedSource as SanityImageFragment)
    : { asset: src.includes('://') ? { url: src } : { _ref: src } }

  const options: SourceOptionsProps = {
    width,
    quality,
    fitMax: true,
    autoFormat: true,
  }

  // Calculate image height
  const aspectRatio =
    'customRatio' in image && image.customRatio
      ? image.customRatio
      : 'dimensions' in image && image.dimensions.aspectRatio
  if (aspectRatio) {
    options.height = width / aspectRatio
  }

  return getSanityImageUrl(image, options) ?? ''
}

/**
 * Builds image source URL from image data and options.
 */
export function getSanityImageUrl(
  image?: SanityImageSource,
  {
    width,
    height,
    format,
    quality,
    fitMax,
    autoFormat,
  }: SourceOptionsProps = {}
) {
  if (!image) {
    return
  }

  try {
    let imageUrlBuilder = imageBuilder.image(image)

    if (width) {
      imageUrlBuilder = imageUrlBuilder.width(Math.round(width))
    }

    if (height) {
      imageUrlBuilder = imageUrlBuilder.height(Math.round(height))
    }

    if (format) {
      imageUrlBuilder = imageUrlBuilder.format(format)
    }

    if (quality) {
      imageUrlBuilder = imageUrlBuilder.quality(quality)
    }

    if (fitMax) {
      imageUrlBuilder = imageUrlBuilder.fit('max')
    }

    if (autoFormat) {
      imageUrlBuilder = imageUrlBuilder.auto('format')
    }

    return imageUrlBuilder.url()
  } catch (error) {
    console.error(error)
  }
}

/**
 * Gets scalar value from any value.
 */
export const getScalarValue = (value?: string) => {
  const scalarValue = value?.match(/-?[\d.]+/g)?.join('')

  if (!scalarValue) {
    return
  }

  return Number(scalarValue)
}

/**
 * Extracts CSS unit from string value
 */
export function getCSSUnit(value?: string) {
  return value
    ?.match(/(%|px|rem|em|vh|vw)/gi)
    ?.filter(Boolean)
    ?.join('')
}

/**
 * Gets fixed width CSS rule from fixed height CSS rule and aspect ratio.
 */
export const getFixedWidth = (aspectRatio: number, fixedHeight?: string) => {
  const heightValue = getScalarValue(fixedHeight)

  if (!heightValue) {
    return
  }

  const heightUnit = getCSSUnit(fixedHeight)

  return `${heightValue / aspectRatio}${heightUnit || 'px'}`
}

/**
 * Gets imgae source from image data.
 */
export const getImageSource = (image: PhotoImage) => {
  // Encode image content to base 64
  if ('content' in image) {
    return `data:${image.type};base64,${Buffer.from(
      image.content,
      'utf-8'
    ).toString('base64')}`
  }

  return JSON.stringify(image)
}

export interface ImageDimensions {
  width?: number
  height?: number
}

/**
 * Get image dimensions based on its size and fixed height.
 */
export const getImageDimensions = (
  image: PhotoImage,
  forceWidth?: number,
  forceHeight?: number
): ImageDimensions => {
  // Return forced dimensions
  if (forceWidth && forceHeight) {
    return {
      width: forceWidth,
      height: forceHeight,
    }
  }

  if (!image.dimensions) {
    return {}
  }

  // Return dimensions for fixed image height
  const fixedHeight = getScalarValue(image.fixedHeight)

  if (fixedHeight) {
    const unit = getCSSUnit(image.fixedHeight)
    const unitScale = unit === 'rem' ? 16 : 1

    return {
      width:
        fixedHeight *
        (image.customRatio || image.dimensions.aspectRatio) *
        unitScale,
      height: fixedHeight * unitScale,
    }
  }

  // Return dimensions for custom aspect ratio
  if (
    image.customRatio &&
    image.customRatio.toFixed(2) !== image.dimensions.aspectRatio.toFixed(2)
  ) {
    if (image.customRatio > image.dimensions.aspectRatio) {
      return {
        width: image.dimensions.width,
        height: image.dimensions.width / image.customRatio,
      }
    }

    return {
      width: image.dimensions.height * image.customRatio,
      height: image.dimensions.height,
    }
  }

  // Return original dimensions
  return {
    width: image.dimensions.width,
    height: image.dimensions.height,
  }
}

/**
 * Gets image loader for Next.js image component.
 */
export const getBasicImageLoader: ImageLoader = ({ src }) => {
  return src
}
