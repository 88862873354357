import groq from 'groq'

import { Preview } from '@lib/sanity/client'
import { getModulesFragment } from './modules'
import { getProductFragment } from './product'
import { getSiteFragment } from './site'
import { blogPostWithoutBodyFragment } from './blog'

export const allSlugsQuery = groq`
  *[
    _type == $type &&
    !(_id in path('drafts.**')) &&
    type != "template"
  ] {
    locale,
    "slug": slug.current
  }
`

export const allProductSlugsQuery = groq`
  *[
    _type == "product" &&
    !(_id in path('drafts.**')) &&
    !wasDeleted &&
    !isDraft
  ] {
    locale,
    "slug": slug.current
  }
`

export const sitemapPagesQuery = groq`
  *[
    _type == $type &&
    !(_id in path('drafts.**')) &&
    locale in $locales &&
    noIndex != true &&
    type != "template"
  ] {
    _type,
    locale,
    "slug": slug.current
  }
`

export const sitemapStaticPagesQuery = groq`
  *[
    _type in $types &&
    !(_id in path('drafts.**')) &&
    locale in $locales &&
    noIndex != true
  ] {
    _type,
    locale
  }
`

export const getShopPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "shopPage" &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      modules[] {
        ${getModulesFragment(preview)}
      },
      hasTransparentHeader,
      headerBackgroundColor,
      seo,
      noIndex,
      "products": *[
        _type == "product" &&
        locale == ^.locale &&
        !wasDeleted
        ${preview.active ? '' : '&& !isDraft'}
      ] | order(title asc) {
        ${getProductFragment(preview)}
      },
      "featuredProductIds": featuredProducts[]->productID
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getCartPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "cartPage" &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      modules[] {
        ${getModulesFragment(preview)}
      },
      hasTransparentHeader,
      headerBackgroundColor,
      seo,
      noIndex
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getHomePageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "homePage" &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      modules[] {
        ${getModulesFragment(preview)}
      },
      hasTransparentHeader,
      headerBackgroundColor,
      seo,
      noIndex
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getErrorPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "errorPage" &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      modules[] {
        ${getModulesFragment(preview)}
      },
      seo,
      noIndex
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getSignupPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "signupPage" &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      modules[] {
        ${getModulesFragment(preview)}
      },
      seo,
      noIndex
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getLoginPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "loginPage" &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      modules[] {
        ${getModulesFragment(preview)}
      },
      seo,
      noIndex
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getPasswordRecoveryPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "passwordRecoveryPage" &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      modules[] {
        ${getModulesFragment(preview)}
      },
      seo,
      noIndex
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getAccountPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "accountPage" &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      modules[] {
        ${getModulesFragment(preview)}
      },
      seo,
      noIndex
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getAccountAddressPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "accountAddressPage" &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      modules[] {
        ${getModulesFragment(preview)}
      },
      seo,
      noIndex
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "page" &&
      slug.current in $slugs &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      modules[] {
        ${getModulesFragment(preview)}
      },
      type,
      hasTransparentHeader,
      headerBackgroundColor,
      seo,
      noIndex
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getCasePageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "case" &&
      slug.current == $slug &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      modules[] {
        ${getModulesFragment(preview)}
      },
      type,
      hasTransparentHeader,
      headerBackgroundColor,
      seo,
      noIndex
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getBlogPostPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "blogPost" &&
      type != "template" &&
      slug.current == $slug &&
      locale == $blogLocale
    ] | order(_updatedAt desc)[0] {
      type,
      hasTransparentHeader,
      headerBackgroundColor,
      seo,
      noIndex,
      modules[] {
        ${getModulesFragment(preview)}
      },
      "slug": slug.current,
      ${blogPostWithoutBodyFragment}
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getBlogPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "blogPage" &&
      locale == $blogLocale
    ] | order(_updatedAt desc)[0] {
      hasTransparentHeader,
      headerBackgroundColor,
      seo,
      noIndex,
      modules[] {
        ${getModulesFragment(preview)}
      },
      "posts": *[
        _type == "blogPost" &&
        type != "template" &&
        locale == ^.locale &&
        (!defined(^.author) || author->slug.current == ^.author->slug.current) &&
        (!defined(^.category) || categories[]->slug.current match ^.category->slug.current)
      ] | order(date desc) | order(_createdAt desc) {
        ${blogPostWithoutBodyFragment}
      },
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getBlogCategoryPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "blogCategory" &&
      slug.current == $slug &&
      locale == $blogLocale
    ] | order(_updatedAt desc)[0] {
      hasTransparentHeader,
      headerBackgroundColor,
      seo,
      noIndex,
      modules[] {
        ${getModulesFragment(preview)}
      },
      "slug": slug.current,
      "posts": *[
        _type == "blogPost" &&
        type != "template" &&
        locale == ^.locale &&
        categories[]->slug.current match ^.slug.current
      ] | order(date desc) | order(_createdAt desc) {
        ${blogPostWithoutBodyFragment}
      }
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getBlogAuthorPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "blogAuthor" &&
      slug.current == $slug &&
      locale == $blogLocale
    ] | order(_updatedAt desc)[0] {
      hasTransparentHeader,
      headerBackgroundColor,
      seo,
      noIndex,
      modules[] {
        ${getModulesFragment(preview)}
      },
      "slug": slug.current,
      "posts": *[
        _type == "blogPost" &&
        type != "template" &&
        locale == ^.locale &&
        author->slug.current == ^.slug.current
      ] | order(date desc) | order(_createdAt desc) {
        ${blogPostWithoutBodyFragment}
      }
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`
