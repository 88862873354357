import { getYouTubeVideoId } from '@lib/video'

enum VideoEmbedType {
  YOUTUBE = 'youtube',
}

interface YouTubeVideoEmbedProps {
  node: {
    type: VideoEmbedType.YOUTUBE
    youtubeVideoUrl: string
  }
}

type VideoEmbedProps = YouTubeVideoEmbedProps

const VideoEmbed = ({ node }: VideoEmbedProps) => {
  const { type, youtubeVideoUrl } = node

  switch (type) {
    case VideoEmbedType.YOUTUBE: {
      const youtubeVideoId = getYouTubeVideoId(youtubeVideoUrl)

      if (!youtubeVideoId) {
        return null
      }

      return (
        <div className="relative w-full pt-[calc((9/16)*100%)]">
          <div className="absolute inset-0">
            <iframe
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              title="YouTube video player"
              allow="encrypted-media"
              allowFullScreen
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      )
    }

    default: {
      return null
    }
  }
}

export default VideoEmbed
