type ColorClassMap = Record<string, string>

const colorClasses: ColorClassMap = {
  white: 'text-white',
  gray: 'text-gray',
  'green-light': 'text-green-light',
  green: 'text-green',
  'green-electric': 'text-green-electric',
  'green-dark': 'text-green-dark',
  'pink-faded': 'text-pink-faded',
  pink: 'text-pink',
  'pink-hot': 'text-pink-hot',
  blue: 'text-blue',
  red: 'text-red',
  yellow: 'text-yellow',
}

interface ColoredTextProps {
  mark: Record<string, string>
  children: string[]
}

const ColoredText = ({ mark, children }: ColoredTextProps) => {
  return <span className={colorClasses[mark.color]}>{children[0]}</span>
}

ColoredText.displayName = 'ColoredText'

export default ColoredText
