import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import axios from 'axios'

import { SanityProductVariantQuery } from '@data/sanity/queries/types/product'
import {
  SanitySiteFragment,
  SanitySiteStrings,
} from '@data/sanity/queries/types/site'
import {
  addLineItemsToShopifyCart,
  createShopifyCart,
  removeLineItemsFromShopifyCart,
  updateLineItemsInShopifyCart,
  updateShopifyCartAttrbites,
  updateShopifyCartNote,
} from './shopify/graphql/cart'
import { ShopifyShop } from './shopify/graphql/shop'
import { getTaxCartAttributes, parseShopifyCart } from './shopify/cart'
import { getShopifyGlobalId } from './shopify/client'
import { getProductVariants } from './sanity/product'
import { getPageUrl, PageType } from './routes'
import { StringsContext } from './strings'
import { LanguageContext, Locale } from './language'
import { getCheckoutUrlStorageKey } from './checkout'
import { ShopContext } from './shop'
import { getPartnerAdsCartAttributes } from './partner-ads'
import { triggerAddToCartFacebookEvent } from './facebook'
import { DiscountContext } from './discount'
import { ValidateVatIdResult } from '@pages/api/tax/validate-vat-id'

export interface CartFormValues {
  vatId: string
  comment: string
}

interface ValidateCartProps {
  errors: ErrorMessages
  vatIdCountryCode: string | null
}

interface CartTotals {
  subTotal: number
  totalDiscount: number
  total: number
}

export interface LineItem extends SanityProductVariantQuery {
  quantity: number
  lineId: string
}

export interface VariantLineItem {
  id: number
  price: number
  quantity: number
}

type CartVariantLineItem = Omit<VariantLineItem, 'price'>

interface AutomaticDiscount {
  title: string
  amount: number
}

export interface Cart {
  id: string
  lineItems: LineItem[]
  subTotal: number
  total: number
  webUrl: string
  automaticDiscount: AutomaticDiscount
  discountCodes: string[]
}

export interface CartDiscountUpdateResponse {
  error?: string
  cart?: Cart
}

interface ErrorMessages {
  [key: string]: string
}

interface CartContextProps {
  cart: Cart
  isCartOpen: boolean
  isCartUpdating: boolean
  isCartProductAdding: boolean
  isCartSubmitting: boolean
  openCartInModal?: boolean
  toggleCart: (newState: boolean) => void
  addItemsToCart: (variantLineItems: CartVariantLineItem[]) => Promise<boolean>
  updateCartItem: (id: string, quantity: number) => Promise<boolean>
  removeItemFromCart: (id: string) => Promise<boolean>
  submitCart: (values: CartFormValues) => Promise<{ errors: ErrorMessages }>
}

const emptyCart: Cart = {
  id: '',
  lineItems: [],
  subTotal: 0,
  total: 0,
  webUrl: '',
  automaticDiscount: {
    title: '',
    amount: 0,
  },
  discountCodes: [],
}

interface CartContextProviderProps {
  site: SanitySiteFragment
  shop: ShopifyShop | null
  children: ReactNode
}

export const CartContext = createContext<CartContextProps>({
  cart: emptyCart,
  isCartOpen: false,
  isCartUpdating: false,
  isCartProductAdding: false,
  isCartSubmitting: false,
  toggleCart: () => null,
  addItemsToCart: async () => false,
  updateCartItem: async () => false,
  removeItemFromCart: async () => false,
  submitCart: async () => ({ errors: {} }),
})

/**
 * Validates cart form.
 */
const validateCart = async (
  strings: SanitySiteStrings,
  values: CartFormValues
) => {
  const results: ValidateCartProps = { errors: {}, vatIdCountryCode: null }

  if (values.vatId) {
    const validationResult = await validateVatId(values.vatId)
    results.vatIdCountryCode = validationResult?.countryCode ?? null

    if (!validationResult?.isValid) {
      results.errors.vatId = !validationResult
        ? strings.cartVatIdError
        : strings.cartInvalidVatIdError
    }
  }

  return results
}

/**
 * Calls API route that handles cart discount updating.
 */
const updateCartDiscount = async (
  locale: Locale,
  cartId: string
): Promise<Cart | undefined> => {
  try {
    const discountUpdateResult = await axios.get<CartDiscountUpdateResponse>(
      '/api/shopify/cart-discount-update',
      {
        params: { cart_id: cartId },
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      }
    )

    if (discountUpdateResult.data?.error || !discountUpdateResult.data?.cart) {
      throw new Error(discountUpdateResult.data.error ?? 'Unknown error')
    }

    return discountUpdateResult.data.cart
  } catch (error) {
    console.log(error)
  }
}

/**
 * Gets cart ID local storage key.
 */
export const getCartIdStorageKey = (locale: Locale) => `cart_id_${locale}`

/**
 * The cart context provider.
 */
export const CartContextProvider = ({
  site,
  shop,
  children,
}: CartContextProviderProps) => {
  const router = useRouter()
  const { locale } = useContext(LanguageContext)
  const { countryCode, shopifyStorefrontClient } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const [cart, setCart] = useState<Cart>(emptyCart)
  const [openCartInModal, setOpenCartInModal] = useState(
    !site.cart?.openInSeparatePage
  )
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [isCartUpdating, setIsCartUpdating] = useState(false)
  const [isCartProductAdding, setIsCartProductAdding] = useState(false)
  const [isCartSubmitting, setIsCartSubmitting] = useState(false)
  const [localeInitialised, setLocaleInitialised] = useState('')

  // Update callbacks
  const saveCart = useCallback((locale: Locale, cart?: Cart) => {
    if (!cart) {
      return
    }

    setCart(cart)

    if (typeof window !== `undefined`) {
      localStorage.setItem(getCartIdStorageKey(locale), cart.id)
    }
  }, [])

  const toggleCart = useCallback(
    (newState: boolean) => {
      if (!openCartInModal) {
        if (newState) {
          router.push(getPageUrl(PageType.CART_PAGE))
        }

        return
      }

      if (isCartOpen !== newState) {
        setIsCartOpen(newState)
      }
    },
    [isCartOpen, openCartInModal, router]
  )

  const addItemsToCart = useCallback(
    async (variantLineItems: CartVariantLineItem[]): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartProductAdding(true)
      setIsCartUpdating(true)

      // Get variant details
      const variantIds = variantLineItems.map(({ id }) => id)
      const productVariants = await getProductVariants(locale, variantIds)

      const cartResponse = await addLineItemsToShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        variantLineItems.map(({ id, quantity }) => {
          const variant = productVariants.find((variant) => id === variant.id)

          return {
            merchandiseId: getShopifyGlobalId('ProductVariant', id),
            sellingPlanId: variant?.sellingPlanId
              ? getShopifyGlobalId('SellingPlan', variant?.sellingPlanId)
              : null,
            quantity,
            attributes: [...getPartnerAdsCartAttributes(true)],
          }
        })
      )

      if (cartResponse.error) {
        return false
      }

      if (site.settings.facebookEvents) {
        productVariants.forEach(async (variant) => {
          await triggerAddToCartFacebookEvent(locale, variant)
        })
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        return false
      }

      saveCart(locale, newCart)

      setIsCartProductAdding(false)
      setIsCartUpdating(false)
      toggleCart(false)

      return !!newCart
    },
    [
      cart.id,
      locale,
      saveCart,
      shopifyStorefrontClient,
      site.settings.facebookEvents,
      toggleCart,
    ]
  )

  const updateCartItem = useCallback(
    async (id: string, quantity: number): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartUpdating(true)

      // Update cart line items
      const cartResponse = await updateLineItemsInShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        [{ id, quantity }]
      )

      if (cartResponse.error) {
        return false
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        return false
      }

      saveCart(locale, newCart)

      setIsCartUpdating(false)

      return !!newCart
    },
    [cart.id, locale, saveCart, shopifyStorefrontClient]
  )

  const removeItemFromCart = useCallback(
    async (id: string): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartUpdating(true)

      // Remove line item from Shopify cart
      const cartResponse = await removeLineItemsFromShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        [id]
      )

      if (cartResponse.error) {
        return false
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        return false
      }

      saveCart(locale, newCart)

      setIsCartUpdating(false)

      return !!newCart
    },
    [cart.id, locale, saveCart, shopifyStorefrontClient]
  )

  const submitCart = useCallback(
    async (values: CartFormValues) => {
      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartSubmitting(true)

      // Validate cart form
      const { errors, vatIdCountryCode } = await validateCart(strings, values)

      if (cart?.id && Object.entries(errors).length === 0) {
        // Update cart attributes
        const taxCartAttributes = getTaxCartAttributes(
          values,
          vatIdCountryCode !== countryCode
        )
        await updateShopifyCartAttrbites(shopifyStorefrontClient, cart.id, [
          ...taxCartAttributes,
          ...getPartnerAdsCartAttributes(),
        ])

        // Update cart note
        await updateShopifyCartNote(
          shopifyStorefrontClient,
          cart.id,
          values.comment ?? ''
        )
      }

      setIsCartSubmitting(false)

      return { errors }
    },
    [cart.id, countryCode, shopifyStorefrontClient, strings]
  )

  // Load initial cart from Shopify
  useEffect(() => {
    if (!shop || localeInitialised === locale) {
      return
    }

    if (!shopifyStorefrontClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setLocaleInitialised(locale)

    const loadCart = async () => {
      const shopCart = 'cart' in shop && shop.cart ? shop.cart : null

      if (shopCart) {
        const currentCart = await parseShopifyCart(locale, shopCart)

        if (currentCart) {
          saveCart(locale, currentCart)
          return
        }
      }

      // Delete saved cart and checkout URL, if cart was not found
      localStorage.removeItem(getCartIdStorageKey(locale))
      localStorage.removeItem(getCheckoutUrlStorageKey(locale))

      // Create a new cart
      const createShopifyCartResponse = await createShopifyCart(
        shopifyStorefrontClient,
        { note: '' }
      )
      const createdCart = await parseShopifyCart(
        locale,
        createShopifyCartResponse.cart
      )

      saveCart(locale, createdCart)
    }
    loadCart()
  }, [locale, localeInitialised, saveCart, shop, shopifyStorefrontClient])

  // Load cart settings (update when switching language)
  useEffect(
    () => setOpenCartInModal(!site.cart?.openInSeparatePage),
    [site.cart?.openInSeparatePage]
  )

  return (
    <CartContext.Provider
      value={{
        cart,
        isCartOpen,
        isCartUpdating,
        isCartProductAdding,
        isCartSubmitting,
        openCartInModal,
        toggleCart,
        addItemsToCart,
        updateCartItem,
        removeItemFromCart,
        submitCart,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

/**
 * Returns cart item count.
 */
export const useCartItemCount = () => {
  const { cart } = useContext(CartContext)

  return useMemo(
    () =>
      cart?.lineItems?.reduce((total, { quantity }) => total + quantity, 0) ??
      0,
    [cart?.lineItems]
  )
}

/**
 * Returns cart totals.
 */
export const useCartTotals = (): CartTotals => {
  const { cart } = useContext(CartContext)
  const { cartDiscountItems } = useContext(DiscountContext)

  return useMemo(
    () => ({
      subTotal: cart?.subTotal ?? 0,
      totalDiscount:
        cartDiscountItems?.reduce(
          (total, { amount, quantity }) => total + amount * quantity,
          0
        ) ?? 0,
      total: cart?.total ?? 0,
    }),
    [cart?.subTotal, cart?.total, cartDiscountItems]
  )
}

/**
 * Validates VAT ID using API route.
 */
export const validateVatId = async (vatId: CartFormValues['vatId']) => {
  try {
    const validationResult = await axios.get<ValidateVatIdResult>(
      '/api/tax/validate-vat-id',
      {
        params: { id: vatId },
        headers: { 'Content-Type': 'application/json' },
      }
    )

    return validationResult.data
  } catch (_) {
    return
  }
}
