import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react'

import { SanityColor } from '@data/sanity/queries/types/modules'

interface LayoutContextProps {
  isHeaderTransparent: boolean
  setIsHeaderTransparent: Dispatch<SetStateAction<boolean>>
  headerBackgroundColor?: SanityColor
  setHeaderBackgroundColor: Dispatch<SetStateAction<SanityColor | undefined>>
}

interface LayoutContextProviderProps {
  pageIsHeaderTransparent: boolean
  pageHeaderBackgroundColor?: SanityColor
  children: ReactNode
}

export const LayoutContext = createContext<LayoutContextProps>({
  isHeaderTransparent: false,
  setIsHeaderTransparent: () => null,
  setHeaderBackgroundColor: () => null,
})

export const LayoutContextProvider = ({
  pageIsHeaderTransparent,
  pageHeaderBackgroundColor,
  children,
}: LayoutContextProviderProps) => {
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(
    pageIsHeaderTransparent
  )
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(
    pageHeaderBackgroundColor
  )

  // Update page header when a new page is loaded
  useEffect(() => {
    if (pageIsHeaderTransparent !== isHeaderTransparent) {
      setIsHeaderTransparent(pageIsHeaderTransparent)
    }
  }, [isHeaderTransparent, pageIsHeaderTransparent])
  useEffect(() => {
    if (pageHeaderBackgroundColor !== headerBackgroundColor) {
      setHeaderBackgroundColor(pageHeaderBackgroundColor)
    }
  }, [headerBackgroundColor, pageHeaderBackgroundColor])

  return (
    <LayoutContext.Provider
      value={{
        isHeaderTransparent,
        setIsHeaderTransparent,
        headerBackgroundColor,
        setHeaderBackgroundColor,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}
