import { Variants } from 'framer-motion'

export const pageTransitionSpeed = 300

export const fadeAnimation: Variants = {
  show: {
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.1,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
}

export const fadeWithSlideAnimation: Variants = {
  show: {
    opacity: 1,
    translateY: 0,
    transition: {
      duration: 0.5,
      delay: 0.1,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
  hide: {
    opacity: 0,
    translateY: 32,
    transition: {
      duration: 0.5,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
}

export const formAnimation: Variants = {
  show: {
    opacity: 1,
    transition: {
      duration: 0.4,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 0.4,
      ease: 'linear',
      when: 'afterChildren',
    },
  },
}
