import { SanityDocument } from '@data/sanity/schema'
import { SanityBorderRadius } from './image'

export interface SanityVimeoVideoFile {
  width: number
  height: number
}

export interface SanityVimeoVideoMedia {
  name: string
  link: string
  width: number
  height: number
  files: SanityVimeoVideoFile[]
}

type MuxStatus = 'preparing' | 'ready' | 'errored'

interface MuxPlaybackId {
  id: string
  policy: 'public' | 'signed'
}

interface MuxAssetMaster {
  status: MuxStatus
  url: string
}

type MuxAssetMasterAccess = 'none' | 'temporary'

type MuxAssetMp4Support = 'none' | 'standard'

interface MuxAssetError {
  type: 'invalid_input' | string
  messages: Array<string>
}

interface MuxStaticRendition {
  name: 'low.mp4' | 'medium.mp4' | 'high.mp4'
  ext: 'mp4'
  height: number
  width: number
  bitrate: number
  filesize: number
}

interface MuxStaticRenditions {
  status: MuxStatus
  files: Array<MuxStaticRendition>
}

interface MuxVideoTrack {
  id: string
  passthrough: string
  status: MuxStatus
  type: 'video'
  duration: number
  max_width: number
  max_height: number
  max_frame_rate: number
}

interface MuxAudioTrack {
  id: string
  passthrough: string
  status: MuxStatus
  type: 'audio'
  duration: number
  max_channels: number
  max_channel_layout: string
}

interface MuxTextTrack {
  id: string
  passthrough: string
  status: MuxStatus
  type: 'text'
  text_type: 'subtitles'
  language_code: string
  closed_captions: boolean
  name: string
}

type MuxTrack = MuxVideoTrack | MuxAudioTrack | MuxTextTrack

export type MuxVideoAsset = {
  assetId: string
  playbackId: string
  uploadId: string
  status: MuxStatus
  thumbTime?: number
  data: {
    id: string
    created_at: string
    status: MuxStatus
    duration?: number
    max_stored_resolution?: 'Audio only' | 'SD' | 'HD' | 'FHD' | 'UHD'
    max_stored_frame_rate?: number
    aspect_ratio?: string
    per_title_encode?: boolean
    is_live?: boolean
    playback_ids?: Array<MuxPlaybackId>
    tracks?: Array<MuxTrack>
    mp4_support: MuxAssetMp4Support
    static_renditions?: MuxStaticRenditions
    master_access: MuxAssetMasterAccess
    master?: MuxAssetMaster
    passthrough?: string
    errors?: MuxAssetError
  }
} & SanityDocument

export interface SanityMuxVideo {
  asset: MuxVideoAsset
}

export enum SanityVideoType {
  VIMEO = 'vimeo',
  MUX = 'mux',
}

export interface SanityVideoSettings {
  controls?: boolean
  autoplay?: boolean
  loop?: boolean
  muted?: boolean
}

export enum SanityVideoAspectRatioValue {
  ASPECT_1_1 = '1:1',
  ASPECT_4_3 = '4:3',
  ASPECT_5_7 = '5:7',
  ASPECT_4_6 = '4:6',
  ASPECT_9_16 = '9:16',
  ASPECT_16_9 = '16:9',
  ASPECT_16_7 = '16:7',
}

export interface SanityVideoAspectRatio {
  custom?: boolean
  base?: SanityVideoAspectRatioValue
  sm?: SanityVideoAspectRatioValue
  md?: SanityVideoAspectRatioValue
  lg?: SanityVideoAspectRatioValue
  xl?: SanityVideoAspectRatioValue
}

export type SanityVideo = {
  type: SanityVideoType
  vimeoVideo?: SanityVimeoVideoMedia
  muxVideo?: SanityMuxVideo
  settings?: SanityVideoSettings
  aspectRatio?: SanityVideoAspectRatio
  borderRadius?: SanityBorderRadius
}
