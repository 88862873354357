import { ButtonHTMLAttributes, forwardRef } from 'react'
import cx from 'classnames'
import { useId } from '@reach/auto-id'

import {
  SanityButtonColor,
  SanityButtonIconAlignment,
  SanityButtonSize,
  SanityButtonVariant,
} from '@data/sanity/queries/types/link'

import Icon, { IconName } from '@components/icon'

export enum ButtonVariant {
  LINK = 'link',
  FILLED = 'filled',
  OUTLINED = 'outlined',
}

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum ButtonColor {
  INHERIT = 'inherit',
  DEFAULT = 'default',
  WHITE = 'white',
  GREEN_LIGHT = 'green-light',
  GREEN_ELECTRIC = 'green-electric',
  PINK_FADED = 'pink-faded',
  PINK_HOT = 'pink-hot',
  PINK = 'pink',
}

export enum ButtonIconAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ButtonIconSize {
  DEFAULT = 'default',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

type VariantColorClassMap = Record<
  ButtonVariant,
  Record<ButtonColor | 'default', string | string[]>
>

export type SizeClassMap = Record<ButtonSize, string | string[]>

export type ColorClassMap = Record<ButtonColor, string | string[]>

export interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  color?: ButtonColor
  icon?: IconName
  iconSize?: ButtonIconSize
  iconAlignment?: ButtonIconAlignment
  isActive?: boolean
}

export const getBaseClasses = (variant?: ButtonVariant, color?: ButtonColor) =>
  cx('inline-flex items-center disabled:opacity-50', {
    'justify-center rounded-full text-center border-[2px] font-medium !leading-[110%] transition-all duration-300':
      variant !== ButtonVariant.LINK,
    // 'hover:shadow-[0_0_0_0.1rem_#000,0_.25em_0_0_#000] hover:-translate-y-1':
    //   variant !== ButtonVariant.LINK && color !== ButtonColor.INHERIT,
    'transition-opacity duration-200 hover:opacity-70':
      variant === ButtonVariant.LINK ||
      variant === ButtonVariant.FILLED ||
      color === ButtonColor.INHERIT,
  })

export const colorClasses: VariantColorClassMap = {
  [ButtonVariant.FILLED]: {
    [ButtonColor.INHERIT]: [],
    [ButtonColor.DEFAULT]: [
      'bg-btn-filled-default-bg text-btn-filled-default-text border-btn-filled-default-border',
      'hover:bg-btn-filled-default-bg-hover hover:text-btn-filled-default-text-hover hover:border-btn-filled-default-border-hover',
    ],
    [ButtonColor.WHITE]: ['border-white bg-white text-black'],
    [ButtonColor.GREEN_LIGHT]: ['border-green-light bg-green-light text-black'],
    [ButtonColor.GREEN_ELECTRIC]: [
      'border-green-electric bg-green-electric text-white',
    ],
    [ButtonColor.PINK_FADED]: ['border-pink-faded bg-pink-faded text-black'],
    [ButtonColor.PINK_HOT]: ['border-pink-hot bg-pink-hot text-white'],
    [ButtonColor.PINK]: ['border-pink bg-pink text-black'],
  },
  [ButtonVariant.OUTLINED]: {
    [ButtonColor.INHERIT]: ['bg-transparent border-current text-current'],
    [ButtonColor.DEFAULT]: [
      'bg-btn-outlined-default-bg border border-btn-outlined-default-border text-btn-outlined-default-text',
      'hover:bg-btn-outlined-default-bg-hover hover:text-btn-outlined-default-text-hover hover:border-btn-outlined-default-border-hover',
    ],
    [ButtonColor.WHITE]: [
      'bg-transparent border-white text-white',
      'hover:border-white hover:bg-white hover:text-black',
    ],
    [ButtonColor.GREEN_LIGHT]: [
      'border-green-light bg-transparent text-green-light',
      'hover:border-green-light hover:bg-green-light hover:text-black',
    ],
    [ButtonColor.GREEN_ELECTRIC]: [
      'border-green-electric bg-transparent text-green-electric',
      'hover:border-green-electric hover:bg-green-electric hover:text-white',
    ],
    [ButtonColor.PINK_FADED]: [
      'border-pink-faded bg-transparent text-pink-faded',
      'hover:border-pink-faded hover:bg-pink-faded hover:text-black',
    ],
    [ButtonColor.PINK_HOT]: [
      'border-pink-hot bg-transparent text-pink-hot',
      'hover:border-pink-hot hover:bg-pink-hot hover:text-white',
    ],
    [ButtonColor.PINK]: [
      'border-pink bg-transparent text-pink',
      'hover:border-pink hover:bg-pink hover:text-black',
    ],
  },
  [ButtonVariant.LINK]: {
    [ButtonColor.INHERIT]: ['text-inherit'],
    [ButtonColor.DEFAULT]: ['text-current'],
    [ButtonColor.WHITE]: ['text-white'],
    [ButtonColor.GREEN_LIGHT]: ['text-green-light'],
    [ButtonColor.GREEN_ELECTRIC]: ['text-green-electric'],
    [ButtonColor.PINK_FADED]: ['text-pink-faded'],
    [ButtonColor.PINK_HOT]: ['text-pink-hot'],
    [ButtonColor.PINK]: ['text-pink'],
  },
}

export const textColorClasses: ColorClassMap = {
  [ButtonColor.INHERIT]: 'text-inherit',
  [ButtonColor.DEFAULT]: 'text-current',
  [ButtonColor.WHITE]: 'text-white',
  [ButtonColor.GREEN_LIGHT]: 'text-green-light',
  [ButtonColor.GREEN_ELECTRIC]: 'text-green-electric',
  [ButtonColor.PINK_FADED]: 'text-pink-faded',
  [ButtonColor.PINK_HOT]: 'text-pink-hot',
  [ButtonColor.PINK]: ['text-pink'],
}

const sizeClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.SMALL]: 'text-base',
  [ButtonSize.NORMAL]: 'text-lg',
  [ButtonSize.LARGE]: 'text-2xl sm:text-3xl md:text-4xl',
}

const spacingClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.SMALL]: 'px-4 py-[.375rem]',
  [ButtonSize.NORMAL]: 'px-6 py-2',
  [ButtonSize.LARGE]: 'px-10 md:px-20 py-3',
}

export const iconSizeClasses: SizeClassMap = {
  [ButtonIconSize.DEFAULT]: '',
  [ButtonIconSize.SMALL]: 'text-lg',
  [ButtonIconSize.NORMAL]: 'text-xl',
  [ButtonIconSize.LARGE]: 'text-2xl md:text-5xl',
}

const activeClasses: VariantColorClassMap = {
  [ButtonVariant.FILLED]: {
    [ButtonColor.INHERIT]: '',
    [ButtonColor.DEFAULT]:
      'bg-btn-filled-default-bg-hover !text-btn-filled-default-text-hover border-btn-filled-default-border-hover',
    [ButtonColor.WHITE]: 'bg-white border-black text-black',
    [ButtonColor.GREEN_LIGHT]: 'border-black bg-green-light text-black',
    [ButtonColor.GREEN_ELECTRIC]: 'border-black bg-green-electric text-black',
    [ButtonColor.PINK_FADED]: 'border-black bg-pink-faded text-black',
    [ButtonColor.PINK_HOT]: 'border-black bg-pink-hot text-white',
    [ButtonColor.PINK]: 'border-black bg-pink text-pink',
  },
  [ButtonVariant.OUTLINED]: {
    [ButtonColor.INHERIT]:
      'bg-transparent border-current text-current opacity-60',
    [ButtonColor.DEFAULT]:
      'bg-btn-outlined-default-bg-hover !text-btn-outlined-default-text-hover border-btn-outlined-default-border-hover',
    [ButtonColor.WHITE]: 'bg-white border-white text-black',
    [ButtonColor.GREEN_LIGHT]: 'border-green-light bg-green-light text-black',
    [ButtonColor.GREEN_ELECTRIC]:
      'border-green-electric bg-green-electric text-black',
    [ButtonColor.PINK_FADED]: 'border-pink-faded bg-pink-faded text-black',
    [ButtonColor.PINK_HOT]: 'border-pink-hot bg-pink-hot text-white',
    [ButtonColor.PINK]: 'border-pink bg-pink text-black',
  },
  [ButtonVariant.LINK]: colorClasses[ButtonVariant.LINK],
}

export const getButtonVariant = (variant?: SanityButtonVariant) => {
  if (!variant) {
    return
  }

  return variant as ButtonVariant
}

export const getButtonSize = (size?: SanityButtonSize) => {
  if (!size) {
    return ButtonSize.DEFAULT
  }

  return size as ButtonSize
}

export const getButtonColor = (color?: SanityButtonColor) => {
  if (!color) {
    return
  }

  return color as ButtonColor
}

export const getButtonIconAlignment = (
  iconAlignment?: SanityButtonIconAlignment
) => {
  if (!iconAlignment) {
    return
  }

  return iconAlignment as ButtonIconAlignment
}

export const getButtonStyles = ({
  variant = ButtonVariant.LINK,
  color = ButtonColor.DEFAULT,
  size = ButtonSize.NORMAL,
  isActive,
}: ButtonProps) => {
  return cx(
    getBaseClasses(variant, color),
    colorClasses[variant][color],
    isActive ? activeClasses[variant][color] : '',
    variant !== ButtonVariant.LINK ? spacingClasses[size] : '',
    sizeClasses[size]
  )
}

export interface ButtonIconProps {
  name: IconName
  alignment?: ButtonIconAlignment
  size?: ButtonIconSize | ButtonSize
  className?: string
}

export const ButtonIcon = ({
  name,
  alignment = ButtonIconAlignment.RIGHT,
  size,
  className,
}: ButtonIconProps) => {
  const id = useId()

  return (
    <span
      className={cx(
        alignment === ButtonIconAlignment.LEFT
          ? [
              'order-first',
              {
                'mr-2': size !== ButtonIconSize.LARGE,
                'mr-4': size === ButtonIconSize.LARGE,
              },
            ]
          : '',
        alignment === ButtonIconAlignment.RIGHT
          ? {
              'ml-3': size !== ButtonIconSize.LARGE,
              'ml-4': size === ButtonIconSize.LARGE,
            }
          : '',
        className
      )}
    >
      <Icon id={`button-icon-${id}`} name={name} />
    </span>
  )
}

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      children,
      className,
      disabled,
      onClick,
      onKeyPress,
      id,
      style,
      type,
      'aria-label': ariaLabel,
      variant,
      size,
      color,
      icon,
      iconSize,
      iconAlignment,
      isActive,
    }: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
    ref
  ) => {
    return (
      <button
        type={type}
        id={id}
        ref={ref}
        className={cx(
          'group',
          getButtonStyles({ variant, size, color, isActive }),
          className
        )}
        onClick={onClick}
        disabled={disabled}
        style={style}
        onKeyPress={onKeyPress}
        aria-label={ariaLabel}
      >
        {children}
        {icon && (
          <ButtonIcon
            name={icon}
            alignment={iconAlignment}
            size={iconSize ?? size}
            className={cx(
              iconSize
                ? iconSizeClasses[iconSize]
                : size
                ? iconSizeClasses[size]
                : {}
            )}
          />
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
