/**
 * Triggers pageview events in GTM and GA.
 */
export const triggerPageviewEvent = () => {
  // Push Google Tag Manager data layer event
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'Pageview',
    pagePath: window.location.pathname,
    pageTitle: document.title,
  })

  // Push Google Analytics event
  if (window['ga']) {
    window.ga('set', {
      location: location.href,
      page: location.pathname,
      title: document.title,
    })
    window.ga('send', 'pageview')
  }
}

/**
 * Triggers newsletter signup event in GTM.
 */
export const triggerNewsletterSignUpEvent = () => {
  window.dataLayer.push({
    event: 'Newsletter sign up',
    pagePath: window.location.pathname,
    pageTitle: document.title,
  })
}

/**
 * Triggers book a demo event in GTM.
 */
export const triggerBookADemoEvent = () => {
  window.dataLayer.push({
    event: 'Book a demo',
    pagePath: window.location.pathname,
    pageTitle: document.title,
  })
}
