import { ReactNode } from 'react'
import cx from 'classnames'

import { useCartItemCount } from '@lib/cart'

import Icon from '@components/icon'

interface CartToggleProps {
  onClick: () => void
  className?: string
  children?: ReactNode
  showCartCount?: boolean
  showCartIcon?: boolean
}

const CartToggle = ({
  onClick,
  className,
  showCartCount,
  children,
  showCartIcon,
}: CartToggleProps) => {
  const cartCount = useCartItemCount()

  return (
    <button
      onClick={onClick}
      className={cx(
        'appearance-none no-underline cursor-pointer font-inherit flex items-center relative bg-transparent p-1 font-semibold',
        className
      )}
    >
      {showCartIcon && (
        <Icon
          id="cart"
          name="Cart"
          className={cx('text-current text-[28px]', {
            'mr-2': children && !showCartCount,
            'mr-4': children && showCartCount,
          })}
        />
      )}

      {children}

      {showCartCount && (
        <span
          className={cx(
            'min-w-[22px] min-h-[22px] rounded-full bg-pageText text-pageBG text-xs flex items-center justify-center',
            {
              'opacity-30': cartCount === 0,
              'ml-2': children && !showCartIcon,
              'absolute top-0 left-5': showCartIcon,
            }
          )}
        >
          {cartCount}
        </span>
      )}
    </button>
  )
}

export default CartToggle
