import groq from 'groq'

import { imageFragment } from './image'

export const blogAuthorFragment = groq`
  name,
  "slug": slug.current,
  photo {
    ${imageFragment}
  }
`

export const blogCategoryFragment = groq`
  name,
  "slug": slug.current,
`

export const blogPostOptionsFragment = groq`
  color,
  showCoverImage,
  showDate,
  showAuthorName,
  showAuthorPhoto,
  showCategories,
  showExcerpt
`

export const blogPostWithoutBodyFragment = groq`
  "id": _id,
  "date": coalesce(date, _createdAt),
  "modifiedDate": _updatedAt,
  title,
  "slug": slug.current,
  author->{
    ${blogAuthorFragment}
  },
  categories[]->{
    ${blogCategoryFragment}
  },
  excerpt,
  coverImage {
    ${imageFragment}
  },
  color,
`

export const blogSettingsFragment = groq`
  inheritContent,
  sourceLocale
`

export const blogSettingsQuery = groq`
  *[_type == "blogSettings" && locale == $locale][0] {
    ${blogSettingsFragment}
  }
`

export const blogPostsQuery = groq`
  *[
    _type == "blogPost" && 
    locale == $locale && 
    !(_id in $excludeIds) &&
    (count((categories[]->slug.current)[@ in $categories]) > 0 || count($categories) == 0)
  ] | order(date desc) [$offset...$limit] {
    ${blogPostWithoutBodyFragment}
  }
`
