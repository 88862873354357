import { SanityContentHorizontalRule } from '@data/sanity/queries/types/content'

interface HorizontalRuleProps {
  node: SanityContentHorizontalRule
}

const HorizontalRule = ({ node }: HorizontalRuleProps) => {
  return <hr className={`bg-${node.color ?? 'current'}`} />
}

HorizontalRule.displayName = 'HorizontalRule'

export default HorizontalRule
