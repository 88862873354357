import {
  getAccountAddressPageQuery,
  getAccountPageQuery,
  getErrorPageQuery,
  getHomePageQuery,
  getLoginPageQuery,
  getPageQuery,
  getPasswordRecoveryPageQuery,
  getShopPageQuery,
  getSignupPageQuery,
  getCartPageQuery,
  sitemapPagesQuery,
  allSlugsQuery,
  allProductSlugsQuery,
  sitemapStaticPagesQuery,
  getCasePageQuery,
} from '@data/sanity/queries/page'
import {
  SanityAccountAddressPageQuery,
  SanityAccountPageQuery,
  SanityErrorPageQuery,
  SanityHomePageQuery,
  SanityLoginPageQuery,
  SanityPageQuery,
  SanityPasswordRecoveryPageQuery,
  SanityShopPageQuery,
  SanitySignupPageQuery,
  SanityCartPageQuery,
  SanityAllSlugs,
  SanityAllProductSlugs,
  SanitySitemapStaticPagesQuery,
  SanitySitemapPagesQuery,
  SanityCasePageQuery,
} from '@data/sanity/queries/types/page'
import {
  SanityDocumentType,
  SanityLocaleQueryParameter,
  SanityLocalesQueryParameter,
  SanitySlugQueryParameter,
  SanitySlugsQueryParameter,
  SanityTypeQueryParameter,
  SanityTypesQueryParameter,
} from '@data/sanity/queries/types/parameters'
import { Locale } from '@lib/language'
import { PageType } from '@lib/routes'
import { fetchQuery, Preview } from './client'

/**
 * Fetches all document slugs in all locales.
 */
export const getAllSlugs = async (type: SanityDocumentType) =>
  fetchQuery<SanityAllSlugs, SanityTypeQueryParameter>(allSlugsQuery, { type })

/**
 * Fetches all product slugs in all locales.
 */
export const getAllProductSlugs = async () =>
  fetchQuery<SanityAllProductSlugs>(allProductSlugsQuery)

/**
 * Fetches pages for sitemap.
 */
export const getSitemapPages = async (
  type: SanityDocumentType,
  locales: Locale[]
) =>
  fetchQuery<
    SanitySitemapPagesQuery,
    SanityTypeQueryParameter & SanityLocalesQueryParameter
  >(sitemapPagesQuery, { type, locales })

/**
 * Fetches static pages for sitemap.
 */
export const getSitemapStaticPages = async (locales: Locale[]) =>
  fetchQuery<
    SanitySitemapStaticPagesQuery,
    SanityLocalesQueryParameter & SanityTypesQueryParameter
  >(sitemapStaticPagesQuery, {
    locales,
    types: [
      PageType.HOME_PAGE,
      PageType.SHOP_PAGE,
      PageType.CART_PAGE,
      PageType.SIGNUP_PAGE,
      PageType.LOGIN_PAGE,
      PageType.PASSWORD_RECOVERY_PAGE,
      PageType.ACCOUNT_PAGE,
      PageType.ACCOUNT_ADDRESS_PAGE,
      PageType.BLOG_PAGE,
    ],
  })

/**
 * Fetches the shop index page.
 */
export const getShopIndexPage = async (locale: Locale, preview: Preview) =>
  fetchQuery<SanityShopPageQuery, SanityLocaleQueryParameter>(
    getShopPageQuery(preview),
    { locale },
    preview
  )

/**
 * Fetches the cart page.
 */
export const getCartPage = async (locale: Locale, preview: Preview) =>
  fetchQuery<SanityCartPageQuery, SanityLocaleQueryParameter>(
    getCartPageQuery(preview),
    { locale },
    preview
  )

/**
 * Fetches the home page.
 */
export const getHomePage = async (locale: Locale, preview: Preview) =>
  fetchQuery<SanityHomePageQuery, SanityLocaleQueryParameter>(
    getHomePageQuery(preview),
    { locale },
    preview
  )

/**
 * Fetches the error page.
 */
export const getErrorPage = async (locale: Locale, preview: Preview) =>
  fetchQuery<SanityErrorPageQuery, SanityLocaleQueryParameter>(
    getErrorPageQuery(preview),
    { locale },
    preview
  )

/**
 * Fetches a specific page based on slug.
 */
export const getPage = async (locale: Locale, slug: string, preview: Preview) =>
  fetchQuery<
    SanityPageQuery,
    SanitySlugsQueryParameter & SanityLocaleQueryParameter
  >(
    getPageQuery(preview),
    { slugs: [`/${slug}`, slug, `/${slug}/`], locale },
    preview
  )

/**
 * Fetches the signup page.
 */
export const getSignupPage = async (locale: Locale, preview: Preview) =>
  fetchQuery<SanitySignupPageQuery, SanityLocaleQueryParameter>(
    getSignupPageQuery(preview),
    { locale },
    preview
  )

/**
 * Fetches the login page.
 */
export const getLoginPage = async (locale: Locale, preview: Preview) =>
  fetchQuery<SanityLoginPageQuery, SanityLocaleQueryParameter>(
    getLoginPageQuery(preview),
    { locale },
    preview
  )

/**
 * Fetches the password recovery page.
 */
export const getPasswordRecoveryPage = async (
  locale: Locale,
  preview: Preview
) =>
  fetchQuery<SanityPasswordRecoveryPageQuery, SanityLocaleQueryParameter>(
    getPasswordRecoveryPageQuery(preview),
    { locale },
    preview
  )

/**
 * Fetches the account page.
 */
export const getAccountPage = async (locale: Locale, preview: Preview) =>
  fetchQuery<SanityAccountPageQuery, SanityLocaleQueryParameter>(
    getAccountPageQuery(preview),
    { locale },
    preview
  )

/**
 * Fetches the account address page.
 */
export const getAccountAddressPage = async (locale: Locale, preview: Preview) =>
  fetchQuery<SanityAccountAddressPageQuery, SanityLocaleQueryParameter>(
    getAccountAddressPageQuery(preview),
    { locale },
    preview
  )

/**
 * Fetches a case page.
 */
export const getCasePage = async (
  locale: Locale,
  slug: string,
  preview: Preview
) =>
  fetchQuery<
    SanityCasePageQuery,
    SanitySlugQueryParameter & SanityLocaleQueryParameter
  >(getCasePageQuery(preview), { slug, locale }, preview)
