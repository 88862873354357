export const cookieBot = (cbid: string) => {
  const script = document.createElement('script')

  script.src = 'https://consent.cookiebot.com/uc.js'
  script.id = 'Cookiebot'
  script.setAttribute('data-cbid', cbid)
  script.setAttribute('data-blockingmode', 'auto')

  const firstScript = document.getElementsByTagName('script')[0]
  firstScript.parentNode?.insertBefore(script, firstScript)
}
