import groq from 'groq'

import { imageFragment } from './image'
import { linkPageFragment } from './link'
import { videoFragment } from './video'
import { buttonStyleFragment } from './link'

export const contentFragment = groq`
  ...,
  markDefs[] {
    ...,
    _type == "link" => {
      id,
      url,
      fontCase,
      isButton,
      isButton == true => {
        "buttonStyles": buttonStyle {
          ${buttonStyleFragment}
        },
      },
      page->{
        ${linkPageFragment}
      },
      "casePage": case->{
        ${linkPageFragment}
      },
    },
  },
  _type == "figure" => {
    ${imageFragment}
  },
  _type == 'quote' => {
    text,
    author,
    authorImage,
    textAlignment,
    size
  },
  _type == "videoEmbed" => {
    type,
    youtubeVideoUrl
  },
  _type == "videoButton" => {
    text,
    id,
    fontCase,
    thumbnail {
      ${imageFragment}
    },
    video {
      ${videoFragment}
    },
    style {
      ${buttonStyleFragment}
    }
  },
  _type == "addToCartButton" => {
    text,
    style {
      ${buttonStyleFragment}
    },
    productVariant->{
      price,
      inStock,
      "id": variantID,
    }
  },
  _type == 'infoBox' => {
    title,
    content,
  },
`
