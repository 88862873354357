import { ReactNode } from 'react'
import cx from 'classnames'

import { SanityContentQuote } from '@data/sanity/queries/types/content'

import Photo from './photo'
import Icon from './icon'

type QuoteProps = Pick<
  SanityContentQuote,
  'author' | 'authorImage' | 'alignment'
> & {
  children: ReactNode
  size?: 'small' | 'large'
}

const Quote = ({
  children,
  author,
  authorImage,
  alignment,
  size = 'large',
}: QuoteProps) => {
  return (
    <div className={cx('quote', { [`text-${alignment}`]: alignment })}>
      <blockquote
        className={cx(
          'text-current text-2xl sm:text-3xl lg:leading-tight mb-0',
          {
            'lg:text-4xl': size === 'small',
            'lg:text-5xl lg:before:mb-8': size === 'large',
            // 'before:content-[""] before:bg-current before:block before:w-[30px] before:h-[30px] before:rounded-full before:mx-auto before:mb-6':
            //   alignment === 'center',
          }
        )}
      >
        {alignment === 'center' && (
          <Icon
            name="SpeechBubble"
            id="quote-icon"
            className="mx-auto text-3xl sm:text-4xl lg:text-5xl mb-6 lg:mb-10"
          />
        )}
        {children}
      </blockquote>

      <div
        className={cx({
          'mt-6': !authorImage?.asset,
          'mt-8': authorImage?.asset,
          'lg:mt-12': size === 'large',
        })}
      >
        {authorImage?.asset && (
          <div className="w-[80px] h-[80px] inline-block relative rounded-full overflow-hidden mb-3">
            <Photo image={authorImage} layout="fill" />
          </div>
        )}
        {author && <p className="text-current text-base">{author}</p>}
      </div>
    </div>
  )
}

export default Quote
