import {
  CartForm,
  CollectionGrid,
  DividerPhoto,
  Grid,
  GridSize,
  GridColumn,
  Hero,
  HeroCaseVideoCarousel,
  HeroVideoSlider,
  ProductHero,
  ReviewWidget,
  Video,
  Marquee,
  ShopSort,
  ShopFilter,
  BlogPostGrid,
  BlogPostHeader,
  BlogPostBody,
  BlogPostReadMore,
  BlogCategoryPostGrid,
  BlogAuthorPostGrid,
  PricingPlans,
} from '@data/sanity/schema'
import { Keyed } from '@lib/helpers'
import { SanityBlocks } from './blocks'
import {
  SanityBlogAuthor,
  SanityBlogCategory,
  SanityBlogPostOptions,
  SanityBlogPostWithoutBody,
} from './blog'
import { SanityContentFragment, SanityLink } from './content'
import { SanityBorderRadius, SanityImageFragment } from './image'
import { SanityLinkReference } from './link'
import { SanityHasSlug } from './page'
import { SanityColorCode, SanityProductFragment } from './product'
import { HasCollectionStrings, HasReviewsStrings } from './site'
import {
  SanityMuxVideo,
  SanityVideo,
  SanityVideoAspectRatio,
  SanityVimeoVideoMedia,
} from './video'

export enum SanityModuleType {
  GRID = 'grid',
  HERO = 'hero',
  HERO_CASE_VIDEO_CAROUSEL = 'heroCaseVideoCarousel',
  HERO_VIDEO_SLIDER = 'heroVideoSlider',
  DIVIDER_PHOTO = 'dividerPhoto',
  PRODUCT_HERO = 'productHero',
  COLLECTION_GRID = 'collectionGrid',
  REVIEW_WIDGET = 'reviewWidget',
  CART_FORM = 'cartForm',
  VIDEO = 'video',
  MARQUEE = 'marquee',
  BLOG_POST_GRID = 'blogPostGrid',
  BLOG_POST_HEADER = 'blogPostHeader',
  BLOG_POST_BODY = 'blogPostBody',
  BLOG_POST_READ_MORE = 'blogPostReadMore',
  BLOG_CATEGORY_POST_GRID = 'blogCategoryPostGrid',
  BLOG_AUTHOR_POST_GRID = 'blogAuthorPostGrid',
  PRICING_PLANS = 'pricingPlans',
}

// Module component types
export enum SanityColor {
  WHITE = 'white',
  GRAY = 'gray',
  GREEN_LIGHT = 'green-light',
  GREEN = 'green',
  GREEN_ELECTRIC = 'green-electric',
  GREEN_DARK = 'green-dark',
  PINK_FADED = 'pink-faded',
  PINK = 'pink',
  PINK_HOT = 'pink-hot',
  BLUE = 'blue',
  RED = 'red',
  YELLOW = 'yellow',
}

export type SanityGridColumnSize = Pick<
  GridSize,
  | 'breakpoint'
  | 'width'
  | 'justify'
  | 'align'
  | 'start'
  | 'justifyContent'
  | 'alignContent'
>

export type SanityGridColumn = Keyed<
  Pick<GridColumn, 'spacing'> & {
    sizes: Array<SanityGridColumnSize>
    blocks: Array<SanityBlocks>
    background?: SanityColor
    borderRadius?: SanityBorderRadius
  }
>

export type SanityGrid = Pick<
  Grid,
  'size' | 'noColumnGaps' | 'noRowGaps' | 'reverseSequence' | 'spacing'
> & {
  background?: SanityColor
  columns: Array<SanityGridColumn>
}

export interface SanityHeroPhotos {
  mobilePhoto?: SanityImageFragment
  desktopPhoto?: SanityImageFragment
}

export type SanityHeroVimeoVideo = Pick<SanityVimeoVideoMedia, 'link' | 'name'>

export enum SanityHeroBackgroundType {
  PHOTO = 'photo',
  VIMEO_VIDEO = 'video',
  MUX_VIDEO = 'mux-video',
}

export enum SanityHeroContentPosition {
  CENTER = 'center',
  BOTTOM_LEFT = 'bottom-left',
}

export interface SanityHero {
  bgType: SanityHeroBackgroundType
  contentPosition: SanityHeroContentPosition
  content?: Array<SanityContentFragment>
  photos?: SanityHeroPhotos
  vimeoVideo?: SanityHeroVimeoVideo
  muxVideo?: SanityMuxVideo
  videoAspectRatio?: SanityVideoAspectRatio
}

export interface SanityHeroCaseVideoCarousel {
  items: Array<{
    content?: Array<SanityContentFragment>
    casePage?: SanityLinkReference & {
      heroModule?: SanityHero
    }
  }>
}

export interface SanityHeroVideoSlider {
  slides: Array<{
    content?: Array<SanityContentFragment>
    muxVideo?: SanityMuxVideo
  }>
}

export interface SanityDividerPhoto {
  background?: SanityColor
  photo: SanityImageFragment
}

export enum SanityFilterType {
  SIMPLE = ' ',
  SWATCH = 'swatch',
}

export enum SanityFilterGroupDisplay {
  DEFAULT = ' ',
  GRID = 'grid',
}

export type SanityFilterGroupOption = SanityHasSlug & {
  type: SanityFilterType
  title: string
  color?: SanityColorCode
}

export type SanityFilterGroup = Keyed<
  SanityHasSlug & {
    display: SanityFilterGroupDisplay
    title: string
    options: Array<SanityFilterGroupOption>
  }
>

export type SanityFilter = Pick<ShopFilter, 'isActive'> & {
  groups?: Array<SanityFilterGroup>
}

export enum SanitySortOptionType {
  FEATURED = 'featured',
  ALPHA_ASCENDING = 'alphaAsc',
  ALPHA_DESCENDING = 'alphaDesc',
  DATE_ASCENDING = 'dateAsc',
  DATE_DESCENDING = 'dateDesc',
  PRICE_ASCENDING = 'priceAsc',
  PRICE_DESCENDING = 'priceDesc',
}

export type SanitySortOption = {
  type: SanitySortOptionType
  title: string
}

export type SanitySort = Pick<ShopSort, 'isActive'> & {
  options?: Array<SanitySortOption>
}

export type SanityCollectionGrid = Pick<CollectionGrid, 'active'> & {
  paginationLimit: number
  title?: string
  filter?: SanityFilter
  sort?: SanitySort
}

export enum SanityReviewWidgetType {
  MAIN = 'main',
  PRODUCT = 'product',
}

export type SanityReviewWidget = {
  type: SanityReviewWidgetType
}

export type SanityCartForm = Pick<CartForm, 'active'>

export type SanityMarqueeSimpleItem = {
  _type: 'simple'
  text: string
}

export type SanityMarqueePhotoItem = {
  _type: 'photo'
  photo: SanityImageFragment
}

export type SanityMarqueeProductItem = {
  _type: 'product'
  _id: string
  product: SanityProductFragment
}

export type SanityMarqueeItem = Keyed<
  SanityMarqueeSimpleItem | SanityMarqueePhotoItem | SanityMarqueeProductItem
>

export type SanityMarquee = Pick<Marquee, 'speed' | 'reverse' | 'pausable'> & {
  items: Array<SanityMarqueeItem>
  content?: Array<SanityContentFragment>
  contentAlignment?: 'left' | 'center' | 'right'
}

export interface SanityBlogPostGrid {
  postsPerRow?: number
  posts: Array<SanityBlogPostWithoutBody>
  author?: SanityBlogAuthor
  category?: SanityBlogCategory
  options?: SanityBlogPostOptions
}

export interface SanityBlogPostHeader {
  post?: SanityBlogPostWithoutBody
  options?: SanityBlogPostOptions
}

export interface SanityBlogPostBody {
  content: SanityContentFragment
}

// export interface SanityBlogPostReadMore {}

export type SanityBlogCategoryPostGrid = Pick<SanityBlogPostGrid, 'options'>

export type SanityBlogAuthorPostGrid = Pick<SanityBlogPostGrid, 'options'>

export interface SanityPricingPlan {
  name?: string
  price?: string
  description?: string
  featured?: boolean
  features?: string
  button?: { label?: string } & SanityLink
}

export interface SanityPricingPlans {
  plans: Array<SanityPricingPlan>
}

// Module raw data types
export type SanityRawModule =
  | Grid
  | Hero
  | DividerPhoto
  | ProductHero
  | CollectionGrid
  | ReviewWidget
  | CartForm
  | Video
  | Marquee
  | BlogPostGrid
  | BlogPostHeader
  | BlogPostBody
  | BlogPostReadMore
  | PricingPlans

// Module data types
export type SanityGridModule = Keyed<Pick<Grid, '_type'> & SanityGrid>

export type SanityHeroModule = Keyed<Pick<Hero, '_type'> & SanityHero>

export type SanityHeroCaseVideoCarouselModule = Keyed<
  Pick<HeroCaseVideoCarousel, '_type'> & SanityHeroCaseVideoCarousel
>

export type SanityHeroVideoSliderModule = Keyed<
  Pick<HeroVideoSlider, '_type'> & SanityHeroVideoSlider
>

export type SanityDividerPhotoModule = Keyed<
  Pick<DividerPhoto, '_type'> & SanityDividerPhoto
>

export type SanityProductHeroModule = Keyed<Pick<ProductHero, '_type'>>

export type SanityCollectionGridModule = Keyed<
  Pick<CollectionGrid, '_type'> & SanityCollectionGrid & HasCollectionStrings
>

export type SanityReviewWidgetModule = Keyed<
  Pick<ReviewWidget, '_type'> & SanityReviewWidget & HasReviewsStrings
>

export type SanityCartFormModule = Keyed<
  Pick<CartForm, '_type'> & SanityCartForm
>

export type SanityVideoModule = Keyed<Pick<Video, '_type'> & SanityVideo>

export type SanityMarqueeModule = Keyed<Pick<Marquee, '_type'> & SanityMarquee>

export type SanityBlogPostGridModule = Keyed<
  Pick<BlogPostGrid, '_type'> & SanityBlogPostGrid
>

export type SanityBlogPostHeaderModule = Keyed<
  Pick<BlogPostHeader, '_type'> & SanityBlogPostHeader
>

export type SanityBlogPostBodyModule = Keyed<
  Pick<BlogPostBody, '_type'> & SanityBlogPostBody
>

export type SanityBlogPostReadMoreModule = Keyed<
  Pick<BlogPostReadMore, '_type'>
>

export type SanityBlogCategoryPostGridModule = Keyed<
  Pick<BlogCategoryPostGrid, '_type'> & SanityBlogCategoryPostGrid
>

export type SanityBlogAuthorPostGridModule = Keyed<
  Pick<BlogAuthorPostGrid, '_type'> & SanityBlogAuthorPostGrid
>

export type SanityPricingPlansModule = Keyed<
  Pick<PricingPlans, '_type'> & SanityPricingPlans
>

export type SanityModule =
  | SanityGridModule
  | SanityHeroModule
  | SanityHeroCaseVideoCarouselModule
  | SanityHeroVideoSliderModule
  | SanityDividerPhotoModule
  | SanityProductHeroModule
  | SanityCollectionGridModule
  | SanityReviewWidgetModule
  | SanityCartFormModule
  | SanityVideoModule
  | SanityMarqueeModule
  | SanityBlogPostGridModule
  | SanityBlogPostHeaderModule
  | SanityBlogPostBodyModule
  | SanityBlogPostReadMoreModule
  | SanityBlogCategoryPostGridModule
  | SanityBlogAuthorPostGridModule
  | SanityPricingPlansModule
