import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react'

import { SanityAnyPage } from '@data/sanity/queries/types/page'
import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { getSanityImageUrl } from './image'

interface SeoContextProps {
  siteTitle?: string
  setSiteTitle: Dispatch<SetStateAction<string | undefined>>
  metaTitle?: string
  setMetaTitle: Dispatch<SetStateAction<string | undefined>>
  metaDescription?: string
  setMetaDescription: Dispatch<SetStateAction<string | undefined>>
  shareTitle?: string
  setShareTitle: Dispatch<SetStateAction<string | undefined>>
  shareDescription?: string
  setShareDescription: Dispatch<SetStateAction<string | undefined>>
  shareGraphicUrl?: string
  setShareGraphicUrl: Dispatch<SetStateAction<string | undefined>>
}

interface SeoContextProviderProps {
  site: SanitySiteFragment
  page?: SanityAnyPage
  children: ReactNode
}

const getSiteTitle = (site: SanitySiteFragment) => site.seo?.siteTitle

const getMetaTitle = (site: SanitySiteFragment, page?: SanityAnyPage) =>
  page?.seo?.metaTitle ?? site.seo?.metaTitle

const getMetaDescription = (site: SanitySiteFragment, page?: SanityAnyPage) =>
  page?.seo?.metaDesc ?? site.seo?.metaDesc

const getShareTitle = (site: SanitySiteFragment, page?: SanityAnyPage) =>
  page?.seo?.shareTitle ?? site.seo?.shareTitle

const getShareDescription = (site: SanitySiteFragment, page?: SanityAnyPage) =>
  page?.seo?.shareDesc ?? site.seo?.shareDesc

const getShareGraphicUrl = (site: SanitySiteFragment, page?: SanityAnyPage) =>
  getSanityImageUrl(page?.seo?.shareGraphic ?? site.seo?.shareGraphic, {
    width: 1200,
    height: 630,
  })

export const SeoContext = createContext<SeoContextProps>({
  setSiteTitle: () => null,
  setMetaTitle: () => null,
  setMetaDescription: () => null,
  setShareTitle: () => null,
  setShareDescription: () => null,
  setShareGraphicUrl: () => null,
})

export const SeoContextProvider = ({
  site,
  page,
  children,
}: SeoContextProviderProps) => {
  const [siteTitle, setSiteTitle] = useState(getSiteTitle(site))
  const [metaTitle, setMetaTitle] = useState(getMetaTitle(site, page))
  const [metaDescription, setMetaDescription] = useState(
    getMetaDescription(site, page)
  )
  const [shareTitle, setShareTitle] = useState(getShareTitle(site, page))
  const [shareDescription, setShareDescription] = useState(
    getShareDescription(site, page)
  )
  const [shareGraphicUrl, setShareGraphicUrl] = useState(
    getShareGraphicUrl(site, page)
  )

  // Update SEO when a new page is loaded
  useEffect(() => {
    const newSiteTitle = getSiteTitle(site)

    if (newSiteTitle !== siteTitle) {
      setSiteTitle(newSiteTitle)
    }
  }, [siteTitle, site])
  useEffect(() => {
    const newMetaTitle = getMetaTitle(site, page)

    if (newMetaTitle !== metaTitle) {
      setMetaTitle(newMetaTitle)
    }
  }, [metaTitle, page, site])
  useEffect(() => {
    const newMetaDescription = getMetaDescription(site, page)

    if (newMetaDescription !== metaDescription) {
      setMetaDescription(newMetaDescription)
    }
  }, [metaDescription, page, site])
  useEffect(() => {
    const newShareTitle = getShareTitle(site, page)

    if (newShareTitle !== shareTitle) {
      setShareTitle(newShareTitle)
    }
  }, [shareTitle, page, site])
  useEffect(() => {
    const newShareDescription = getShareDescription(site, page)

    if (newShareDescription !== shareDescription) {
      setShareDescription(newShareDescription)
    }
  }, [shareDescription, page, site])
  useEffect(() => {
    const newShareGraphicUrl = getShareGraphicUrl(site, page)

    if (newShareGraphicUrl !== shareGraphicUrl) {
      setShareGraphicUrl(newShareGraphicUrl)
    }
  }, [shareGraphicUrl, page, site])

  return (
    <SeoContext.Provider
      value={{
        siteTitle,
        setSiteTitle,
        metaTitle,
        setMetaTitle,
        metaDescription,
        setMetaDescription,
        shareTitle,
        setShareTitle,
        shareDescription,
        setShareDescription,
        shareGraphicUrl,
        setShareGraphicUrl,
      }}
    >
      {children}
    </SeoContext.Provider>
  )
}
